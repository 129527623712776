import {
  MentorshipProgramProfileQuestion,
  ProgramLevelQuestion,
} from 'redux/schemas/models/mentoring-program-profile';
import { getMentorshipProgramProfileQuestionsList } from 'redux/selectors/mentorship-program-profile';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { useContext, useEffect, useMemo } from 'react';
import { useAppDispatch } from 'redux/store';
import { useInView } from 'react-intersection-observer';
import { getMentorshipProgramProfileQuestions, ProgramProfileQuestionType } from 'redux/actions/mentoring-program-profile';
import MentoringProgramContext from '../../context';
import { GetProgramProfileQuestionParams } from '../../program-configuration/program-profile-setup/types';
import SelectableQuestionRow from './selectable-question-row';
import { PAGE_SIZE } from '../../constants';

type SelectableProgramLevelQuestionsProps = {
  selected: number[];
  onSelect: (questionId: number, isSelected: boolean) => void;
};

const mapToSelectableQuestion = (
  question: MentorshipProgramProfileQuestion,
) => {
  const { id: questionId, profileQuestion, isAttachedToRule } = question;
  const { questionList } = (profileQuestion as ProgramLevelQuestion) || {};
  const { questionText } = questionList || {};
  return { questionId, questionText, isAttachedToRule };
};

const SelectableProgramLevelQuestions = ({
  selected,
  onSelect,
}: SelectableProgramLevelQuestionsProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const { ref: endRef, inView } = useInView();

  const params = useMemo(
    () => ({
      type: ProgramProfileQuestionType.PROGRAM_LEVEL,
      pageSize: PAGE_SIZE,
    }),
    [],
  );

  const { result: programLevelQuestions, loadMore } = usePaginatedApi<
  MentorshipProgramProfileQuestion,
  GetProgramProfileQuestionParams
  >(
    p => dispatch(
      getMentorshipProgramProfileQuestions({ mentorshipProgramId, ...p }),
    ).then(action => action.payload),
    params,
    getMentorshipProgramProfileQuestionsList,
  );

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [loadMore, inView]);

  return (
    <>
      {programLevelQuestions?.filter(question => question.isRequired)
        .map(mapToSelectableQuestion)
        .map(({ questionId, questionText, isAttachedToRule }) => (
          <SelectableQuestionRow
            key={questionId}
            isSelected={selected.includes(questionId)}
            questionId={questionId}
            questionText={questionText}
            onSelect={onSelect}
            isAttachedToRule={isAttachedToRule}
          />
        ))}
      <div ref={endRef} />
    </>
  );
};

export default SelectableProgramLevelQuestions;
