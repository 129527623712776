import React from 'react';
import debounce from 'lodash/debounce';

import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import NvTable from 'shared/components/nv-table';
import { getUsersList } from 'redux/selectors/users';
import AthenaTextInput from 'athena/components/text-input';
import { searchOrgUsersForProgram } from 'redux/actions/users';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { OrganizationUsersSearchUserResult } from 'redux/schemas/api/user';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import ParticipantRow, { ParticipantRowProps } from 'athena/components/mentoring-program/participants/add-participants-modal/participant-row';
import NvNoResults from 'shared/components/nv-no-results-panel';
import { PageSizeParams } from '../../constants';

type ParticipantsParams = PageSizeParams & {
  searchTerm: string;
};

type Props = {
  reachedEnd: boolean;
  onAdd?: () => void;
};

const AddBySearching = (props: Props) => {
  const { reachedEnd, onAdd } = props;

  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = React.useState('');
  const { mentoringProgram } = React.useContext(MentoringProgramContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchTerm = React.useCallback(debounce(setSearchTerm, 500), []);

  const params = React.useMemo(() => ({
    searchTerm,
    pageSize: 20,
  }), [searchTerm]);

  const { result, loadMore } = usePaginatedApi<OrganizationUsersSearchUserResult, ParticipantsParams>((p) => {
    if (p.searchTerm) {
      return dispatch(searchOrgUsersForProgram({
        page: p.page,
        pageSize: p.pageSize,
        queryTerm: p.searchTerm,
        programId: mentoringProgram.id,
      })).then((action) => action.payload);
    }

    return undefined;
  }, params, getUsersList as any);

  const data = result?.map(item => ({ ...item, onAdd } as ParticipantRowProps)) ?? [];

  React.useEffect(() => {
    if (reachedEnd) {
      loadMore();
    }
  }, [loadMore, reachedEnd]);

  return (
    <div>
      <div className='heading-5 mb-2'>
        {t.MENTORING_PROGRAMS.PARTICIPANTS.ADD_EXISTING()}
      </div>
      <AthenaTextInput
        icon='search'
        value={searchTerm}
        onClick={loadMore}
        showLabel={false}
        iconPosition='right'
        onChange={(e) => debouncedSetSearchTerm(e.target.value)}
        placeholder={t.USER_MANAGEMENT.MANUAL_ADD_MODAL.SEARCH_PLACEHOLDER_NO_NOVOEDID()}
      />
      <NvTable<ParticipantRowProps>
        data={data}
        RowComponent={ParticipantRow}
        className='d-flex flex-column'
        columns={[{
          id: 'name',
          gridTemplateColumn: '1fr',
          accessorFn: (user) => user,
        }, {
          id: 'add',
          accessorFn: (user) => user,
          gridTemplateColumn: '1fr',
        }]}
      />
      {result?.length === 0 && (
        <NvNoResults
          action={() => setSearchTerm('')}
          hideClearSearch={false}
          clearText={t.SEARCH.CLEAR()}
          noResultsText={t.SEARCH.NO_RESULTS_FOUND()}
        />
      )}
    </div>
  );
};

export default AddBySearching;
