import { createReducer } from '@reduxjs/toolkit';
import {
  addNewProgramLevelQuestionDraft,
  createMentorshipProgramProfileQuestions,
  deleteMentorshipProgramProfileQuestion,
  deleteProgramLevelQuestionDraft,
  getMentorshipProgramProfileQuestions,
  updateMentorshipProgramProfileQuestion,
} from 'redux/actions/mentoring-program-profile';
import { normalize } from 'normalizr';
import { MentorshipProgramProfileQuestionsSchema } from 'redux/schemas/api/mentoring-program-profile';
import { replaceArrays } from 'shared/lodash-utils';
import { mergeWith } from 'lodash';
import { initialRootState } from '.';

const setProgramProfileActionHandler = (state, action) => {
  const normalized = normalize(
    action.payload,
    MentorshipProgramProfileQuestionsSchema,
  );

  mergeWith(state.models, normalized.entities, replaceArrays);
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(
      getMentorshipProgramProfileQuestions.fulfilled,
      setProgramProfileActionHandler,
    )
    .addCase(
      createMentorshipProgramProfileQuestions.fulfilled,
      (state, action) => {
        const { draftId } = action.meta.arg;
        if (draftId) delete state.models.mentorshipProgramProfileQuestions[draftId];
        setProgramProfileActionHandler(state, action);
      },
    )
    .addCase(
      updateMentorshipProgramProfileQuestion.fulfilled,
      (state, action) => {
        const { questionId } = action.meta.arg;
        state.models.mentorshipProgramProfileQuestions[questionId] = action.payload;
      },
    )
    .addCase(
      deleteMentorshipProgramProfileQuestion.fulfilled,
      (state, action) => {
        const { questionId } = action.meta.arg;
        delete state.models.mentorshipProgramProfileQuestions[questionId];
      },
    )
    .addCase(addNewProgramLevelQuestionDraft, (state, action) => {
      const { newItem } = action.payload;
      const { tempId } = newItem;
      state.models.mentorshipProgramProfileQuestions[tempId] = newItem;
    })
    .addCase(deleteProgramLevelQuestionDraft, (state, action) => {
      const { tempId } = action.payload;
      delete state.models.mentorshipProgramProfileQuestions[tempId];
    });
});
