/* @ngInject */
export default function AthenaRoutes(
  $stateProvider,
) {
  $stateProvider
    .state('mentoring-programs-abstract', {
      parent: 'institution-abstract',
      url: '/mentoring-programs',
      abstract: true,
      views: {
        'main@mainGridContent': {
          controller: 'MentoringProgramsCtrl',
          templateUrl: 'athena/templates/mentoring-programs.html',
        },
      },
      data: {
        headerClass: 'no-header',
      },
      resolve: {
        checkAthenaEnabled($state, requestInstitution, InstitutionsManager) {
          if (!InstitutionsManager.institution.isMentorshipEnabled) {
            $state.go('institution-dashboard', {
              institutionId: InstitutionsManager.institution.id,
            }, { replace: true });
          }
        },
      },
    })
    .state('mentoring-programs', {
      parent: 'mentoring-programs-abstract',
      url: '/',
    })
    .state('mentoring-program-wrapper', {
      abstract: true,
      url: '/mentoring-programs/:programId',
      parent: 'user-grid-abstract',
      views: {
        'main-panel@user-grid-abstract': {
          templateUrl: 'layouts/templates/main-content.html',
        },
      },
      data: {
        level: 1,
      },
    })
    .state('mentoring-program-abstract', {
      abstract: true,
      parent: 'mentoring-program-wrapper',
      views: {
        'main@mentoring-program-wrapper': {
          controller: 'MentoringProgramCtrl',
          templateUrl: 'athena/templates/mentoring-program.html',
        },
      },
      data: {
        headerClass: 'no-header',
      },
    })
    .state('mentoring-program-root', {
      url: '/',
      parent: 'mentoring-program-abstract',
    })
    .state('mentoring-program-participants', {
      url: '/participants',
      parent: 'mentoring-program-abstract',
    })
    .state('mentoring-program-connections', {
      url: '/connections',
      parent: 'mentoring-program-abstract',
    })
    .state('mentoring-program-settings', {
      url: '/settings',
      parent: 'mentoring-program-abstract',
    });
}
