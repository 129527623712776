import React from 'react';
import omit from 'lodash/omit';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';

import t from 'react-translate';
import { white } from 'styles/global_defaults/colors';
import ProgramHeader from 'athena/components/program-header';
import { screenMdMin } from 'styles/global_defaults/media-queries';
import ResponsiveTabs, { Tab } from 'athena/components/responsive-tabs';
import { almostWhite, darkGray, shadedWhite } from 'athena/styles/colors';
import Connections from 'athena/components/mentoring-program/connections';
import Participants from 'athena/components/mentoring-program/participants';
import useMentoringProgramRole from 'athena/hooks/use-mentoring-program-role';
import { standardSpacing, tripleSpacing } from 'styles/global_defaults/scaffolding';
import { LoadingTabs, LoadingContent } from 'athena/components/mentoring-program/loading-ui';
import { getCreateConnectionModalSettings } from 'redux/selectors/mentorship-program-connections';
import CreateConnectionFlyoutModal from 'athena/components/mentoring-program/modals/create-connection-flyout-modal';
import MentoringProgramContext, { MentoringProgramHomeUIContext } from 'athena/components/mentoring-program/context';
import { useAppDispatch } from 'redux/store';
import setupEventsListener from 'athena/helpers/pusher-helper';
import { config } from '@config/pendo.config.json';

const Root = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isAdmin } = useMentoringProgramRole();
  const [innerScroll, setInnerScroll] = React.useState(0);
  const scrollContainerRef = React.useRef<HTMLDivElement>();
  const [scrollPercentage, setScrollPercentage] = React.useState(0);
  const { isLoading, countState, mentoringProgram } = React.useContext(MentoringProgramContext);

  const { showModal: showCreateConnectionFlyoutModal } = useSelector(getCreateConnectionModalSettings);
  const innerScrollHandler = (e) => {
    setInnerScroll(e.currentTarget.scrollTop);
  };

  const isInnerScrollEnabled = scrollPercentage === 100;

  const tabs: (Tab & { renderContent: () => React.ReactNode, path: string, allowed: boolean })[] = React.useMemo(() => {
    // Anchors have styles on hover and on focus by default so I make sure they
    // don't show up in this case with these styles
    const tabStyles = css`
      &:hover, &:focus {
        color: ${darkGray};
      }
    `;

    // Only defining tabs when mentoring program is loaded, one reason to do
    // that is because variable `isAdmin` is only defined correctly when
    // mentoring program is fetched (given they come together in the same API),
    // otherwise it may return null (not loaded yet) being interpreted as falsy
    // causing unintended behavior in hooks of this component that depend on the
    // value of `allowed` property, so making tabs available only when it's
    // safe.
    return mentoringProgram ? [
      {
        allowed: isAdmin,
        id: 'participants',
        path: '/participants',
        text: t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANTS(),
        renderContainer: (props) => <Link to='/participants' css={tabStyles} {...props} />,
        renderContent: () => <Participants onScroll={innerScrollHandler} scrollEnabled={isInnerScrollEnabled} />,
        renderAfterText: () => <span css={css`white-space: pre;`}> ({countState.result?.enrollmentsCount})</span>,
        dataQa: config.pendo.athena.mentorshipProgram.tabs.participantsTab,
      },
      {
        allowed: isAdmin,
        id: 'connections',
        path: '/connections',
        text: t.MENTORING_PROGRAMS.CONNECTIONS.TITLE(),
        renderContainer: (props) => <Link to='/connections' css={tabStyles} {...props} />,
        renderContent: () => <Connections onScroll={innerScrollHandler} scrollEnabled={isInnerScrollEnabled} />,
        renderAfterText: () => <span css={css`white-space: pre;`}> ({countState.result?.connectionsCount})</span>,
        dataQa: config.pendo.athena.mentorshipProgram.tabs.connectionsTab,
      },
    ] : [];
  }, [isAdmin, mentoringProgram, countState.result, isInnerScrollEnabled]);

  const isTablet = useMediaQuery({
    query: `(max-width: ${screenMdMin}px)`,
  });

  const tabsContainerTranslate = (60 / 100) * scrollPercentage;

  // Every flex-grow element inside "tabs-container" which nearest parent with
  // explicit height is "tabs-container", needs to use this class in order to
  // make tab content inner css "overflow" features work properly.
  // More reference: https://www.w3.org/TR/css-flexbox-1/#min-size-auto
  const withOverflowFlexGrowClass = 'with-overflow-flex-grow';

  const withOverflowFlexGrowStyles = css`
    .${withOverflowFlexGrowClass} {
      flex: 1;
      min-height: 0;
    }
  `;

  const styles = css`
    flex: 1;
    overflow: hidden;
    ${innerScroll === 0 && css`
      overflow-y: auto;
    `};

    .scroll-content {
      height: calc(100vh + 200px);

      .program-content {
        height: calc(100vh - 150px);
        background-color: ${almostWhite};
        padding: 0 ${isTablet ? standardSpacing : 120}px;

        & > .tabs-container {
          border-radius: 10px;
          background-color: ${white};
          height: calc(100% + ${120 - tabsContainerTranslate}px);
          transform: translateY(-${120 - tabsContainerTranslate}px);
          ${withOverflowFlexGrowStyles};

          .tabs-header {
            height: ${tripleSpacing}px;
            border-bottom: 1px solid ${shadedWhite};
          }
        }
      }
    }
  `;

  const isRoot = matchPath(location.pathname, {
    path: '/',
  }).isExact;

  React.useEffect(() => {
    if (isRoot) {
      const defaultTab = tabs.find((tab) => tab.allowed);

      if (defaultTab) {
        history.replace(defaultTab.path);
      }
    }
  }, [isRoot, tabs, history]);

  const currentTab = React.useMemo(() => tabs.find((tab) => matchPath(location.pathname, {
    path: tab.path,
  })), [tabs, location.pathname]);

  React.useEffect(() => {
    if (currentTab && !currentTab.allowed) {
      history.replace('/');
    }
  }, [history, currentTab]);

  const uiContextValue = {
    withOverflowFlexGrowClass,
  };

  React.useEffect(() => {
    const cleanup = setupEventsListener(mentoringProgram?.id, dispatch);

    return () => {
      cleanup();
    };
  }, [mentoringProgram?.id, dispatch]);

  return (
    <MentoringProgramHomeUIContext.Provider value={uiContextValue}>
      <div
        css={styles}
        ref={scrollContainerRef}
        onScroll={() => {
          const { clientHeight, scrollHeight, scrollTop } = scrollContainerRef.current;

          const total = scrollHeight - clientHeight;

          setScrollPercentage((100 / total) * scrollTop);
        }}
      >
        <div
          className='scroll-content'
        >
          <ProgramHeader scrollPercentage={scrollPercentage} />
          <div className='program-content'>
            <div className='tabs-container d-flex flex-column'>
              <div className='tabs-header'>
                {(isLoading || countState.isLoading) ? (
                  <LoadingTabs className='h-100' />
                ) : (
                  <ResponsiveTabs
                    selectedTab={currentTab?.id}
                    tabs={tabs.filter((tab) => tab.allowed).map((tab) => omit(tab, ['path', 'renderContent']) as Tab)}
                  />
                )}
              </div>
              <div className={`p-4 d-flex flex-column ${withOverflowFlexGrowClass}`}>
                {isLoading ? (
                  <LoadingContent />
                ) : (
                  <>
                    {mentoringProgram && currentTab?.renderContent()}
                  </>
                )}
              </div>
            </div>
          </div>
          {showCreateConnectionFlyoutModal && <CreateConnectionFlyoutModal />}
        </div>
      </div>
    </MentoringProgramHomeUIContext.Provider>
  );
};

export default Root;
