/* eslint-disable react/no-array-index-key */
import React from 'react';
import { css } from '@emotion/react';
import {
  quarterSpacing,
  threeQuartersSpacing,
  doubleSpacing,
  halfSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { paleGray } from 'athena/styles/colors';
import { gray6 } from 'styles/global_defaults/colors';
import NvIcon from 'shared/components/nv-icon';
import { commonStyles } from 'athena/components/mentoring-program/participants/participant-row/styles';

type Props = {
  className?: string;
};

const LoadingRow = (props: Props) => {
  const { className } = props;

  const styles = css`
    ${commonStyles};

    .avatar {
      &:not(:first-child) {
        margin-left: -${quarterSpacing}px;
      }
    }
    .participant-avatars {
      margin-right: ${threeQuartersSpacing}px;
    }
    .participant-names {
      gap: ${threeQuartersSpacing}px;
      overflow: hidden;

      i {
        color: ${paleGray};
      }
    }
    .avatar-placeholder {
      width: ${doubleSpacing}px;
      height: ${doubleSpacing}px;
      border-radius: ${standardSpacing}px;
    }
    .avatar-placeholder,
    .name-placeholder {
      background-color: ${gray6};
    }
    .name-placeholder {
      width: 100px;
      height: ${halfSpacing}px;
      border-radius: ${quarterSpacing}px;
    }
  `;

  return (
    <div css={styles} className={`d-flex align-items-center ${className}`}>
      <div className='participant-avatars d-inline-flex'>
        {Array.from({ length: 2 }).map((_, index) => (
          <div key={index} className='avatar position-relative avatar-placeholder' />
        ))}
      </div>
      <div className='participant-names d-flex align-items-center'>
        <div className='name-placeholder' />
        <NvIcon icon='link' size='smallest' />
        <div className='name-placeholder' />
      </div>
    </div>
  );
};

export default LoadingRow;
