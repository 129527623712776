/* eslint-disable react/no-array-index-key */
import t from 'react-translate';
import { css } from '@emotion/react';
import { useContext } from 'react';
import {
  almostBlack,
  dividerMedium,
  lightBlack,
  shadedWhite,
} from 'athena/styles/colors';
import { doubleSpacing } from 'styles/global_defaults/scaffolding';
import MentoringProgramContext from '../../context';
import OrgLevelFieldsSection from './org-level';
import ProgramLevelFieldsSection from './program-level';

const styles = css`
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;

  .description {
    color: ${almostBlack};
  }
  .subtitle {
    color: ${almostBlack};
  }
  .headers {
    color: ${almostBlack};
    height: 45px;
    border-bottom: 1px solid ${dividerMedium};
  }
  .field-row {
    height: ${doubleSpacing}px;
    border-bottom: 1px solid ${shadedWhite};

    .field-name {
      color: ${almostBlack};
    }
    .field-type {
      color: ${lightBlack};
    }
  }
`;

const ProgramProfileSetup = () => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};

  const sections = [OrgLevelFieldsSection, ProgramLevelFieldsSection];

  return (
    <div css={styles}>
      {mentorshipProgramId && (
        <div className='mb-4'>
          <div className='title heading-3 mb-2'>
            {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.TITLE()}
          </div>
          <div className='description text-regular mb-5'>
            {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.DESCRIPTION()}
          </div>
          {sections.map((Section, index) => (
            <Section key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProgramProfileSetup;
