import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { AutoMatchingStatus, ProgramCounts, SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { AdminBitValue } from 'redux/schemas/models/mentoring-program-enrollments';
import {
  AutoMatchingType,
  MentoringProgram,
  MentoringProgramNormalized,
} from 'redux/schemas/models/mentoring-programs';

type CreateMentoringProgramParams = {
  name: string;
  description: string;
};

// eslint-disable-next-line import/prefer-default-export
export const createMentoringProgram = createAsyncThunk<MentoringProgram, CreateMentoringProgramParams>(
  'CREATE_MENTORING_PROGRAM',
  async (params) => {
    const response = await axios.post('/mentorship_programs.json', {
      mentorshipProgram: params,
    });

    return response.data.result.mentorshipProgram;
  },
);

type AutoMatchingParams = {
  autoMatchingEnabled: boolean;
  autoMatchingType: AutoMatchingType;
};

type UpdateMentoringProgramParams = Partial<CreateMentoringProgramParams> & Partial<AutoMatchingParams> & {
  id: number;
  maxMentees?: number;
  mentorAlias?: string;
  menteeAlias?: string;
};

export const updateMentoringProgram = createAsyncThunk<MentoringProgram, UpdateMentoringProgramParams>(
  'UPDATE_MENTORING_PROGRAM',
  async ({
    id,
    ...restParams
  }) => {
    const params = {
      name: restParams.name || undefined,
      description: restParams.description || '',
      auto_matching_enabled: restParams.autoMatchingEnabled || false,
      auto_matching_type: restParams.autoMatchingType || undefined,
      program_alias: (restParams.mentorAlias || restParams.menteeAlias) ? {
        mentor: restParams.mentorAlias,
        mentee: restParams.menteeAlias,
      } : undefined,
      concurrent_mentor_connection: restParams.maxMentees,
    };
    const response = await axios.put(`/mentorship_programs/${id}.json`, {
      mentorshipProgram: params,
    });

    return response.data.result.mentorshipProgram;
  },
);

export const deleteMentoringProgram = createAsyncThunk<boolean, number>(
  'DELETE_MENTORING_PROGRAM',
  async (id) => {
    const response = await axios.delete(`/mentorship_programs/${id}.json`);

    return response.data.result;
  },
);

export type GetMentoringProgramParams = {
  page: number;
  pageSize: number;
};

export const getMentoringPrograms = createAsyncThunk<{ response: MentoringProgramNormalized[] }, GetMentoringProgramParams>(
  'GET_MENTORING_PROGRAMS',
  async (params) => {
    const response = await axios.get('/mentorship_programs', {
      params,
    });

    return {
      response: response.data.result,
    };
  },
);

export const getMentoringProgram = createAsyncThunk<MentoringProgram, number>(
  'GET_MENTORING_PROGRAM',
  async (programId) => {
    const response = await axios.get(`mentorship_programs/${programId}.json`);

    return response.data.result;
  },
);

type FetchProgramCountsParams = {
  programId: number;
  setLoading: boolean;
};

export const fetchProgramCounts = createAsyncThunk<ProgramCounts, FetchProgramCountsParams>(
  'FETCH_PROGRAM_COUNTS',
  async ({ programId }) => {
    const response = await axios.get(`/mentorship_programs/${programId}/count.json`);

    return response.data.result;
  },
);

export const setAutoMatchingStatus = createAction<{
  autoMatchingStatus: AutoMatchingStatus;
}>('SET_AUTOMATCHING_STATUS');

export const setAutoMatchingLastUpdated = createAction<{
  mentorshipProgramId: number;
  autoMatchingLastUpdated: Date;
}>('SET_AUTOMATCHING_LAST_UPDATED');

export const setConnectionsCount = createAction<{
  totalConnectionsCount: number;
}>('SET_CONNECTIONS_COUNT');

export const setSaveStatus = createAction<{
  newStatus: SaveStatus;
}>('SET_SAVE_STATUS');
