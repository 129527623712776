import React from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/schemas';
import { AngularContext } from 'react-app';
import { useAppDispatch } from 'redux/store';
import NvRouter from 'shared/components/nv-router';
import Root from 'athena/components/mentoring-program/root';
import { getMentoringProgram } from 'redux/selectors/mentoring-programs';
import { useProgramSummaryCounts } from 'athena/hooks/use-program-summary-counts';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { getMentoringProgram as fetchMentoringProgram } from 'redux/actions/mentoring-programs';
import ProgramConfiguration from './program-configuration';

const contentRoutes = [
  {
    path: '/settings',
    component: ProgramConfiguration,
  },
  {
    path: '/',
    component: Root,
  },
];

const MentoringProgram = () => {
  const dispatch = useAppDispatch();
  const countState = useProgramSummaryCounts();
  const [isLoading, setIsLoading] = React.useState(false);
  const { injectServices } = React.useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { programId } = $state.params;

  const routerBasename = `/#!/mentoring-programs/${programId}`;

  const mentoringProgram = useSelector((state: RootState) => getMentoringProgram(state, programId));

  const styles = css`
    height: 100vh;
  `;

  React.useEffect(() => {
    setIsLoading(true);
    dispatch(fetchMentoringProgram(programId)).then(() => {
      setIsLoading(false);
    });
  }, [dispatch, programId]);

  const contextValue = {
    isLoading,
    countState,
    mentoringProgram,
  };

  return (
    <MentoringProgramContext.Provider value={contextValue}>
      <div className='d-flex' css={styles}>
        <NvRouter routes={contentRoutes} basename={routerBasename} />
      </div>
    </MentoringProgramContext.Provider>
  );
};

export default MentoringProgram;
