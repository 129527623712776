import { css } from '@emotion/react';
import t from 'react-translate';
import {
  doubleSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  AutoMatchingRule,
  Expression,
} from 'redux/schemas/models/mentoring-program-matching-rules';
import {
  MentorshipProgramProfileQuestion,
  QuestionType,
  ProgramLevelQuestion,
  OrgLevelQuestion,
} from 'redux/schemas/models/mentoring-program-profile';
import {
  almostBlack,
  dividerMedium,
  novoEdBlue,
  shadedWhite,
} from 'athena/styles/colors';
import { useAppDispatch } from 'redux/store';
import { updateMentorshipProgramAutoMatchingRule } from 'redux/actions/mentoring-program-matching-rules';
import { useContext } from 'react';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { setSaveStatus } from 'redux/actions/mentoring-programs';
import { wrapThunkAction } from 'redux/utils';
import ClickableContainer from 'components/clickable-container';
import { config } from '@config/pendo.config.json';
import OptionsMenu from './options-menu';
import ImportanceLevelSlider from './importance-level-slider';
import { gridStyles } from './styles';
import MentoringProgramContext from '../../context';
import { getName } from '../../constants';

const styles = css`
  ${gridStyles};
  border-bottom: 1px solid ${shadedWhite};

  .matching-rule-details {
    gap: ${standardSpacing}px;
    .question-text {
      height: 35px;
      width: fit-content;
      border-radius: ${quarterSpacing}px;
      background-color: rgba(14, 114, 237, 0.05);
    }
    .expression-description {
      color: ${almostBlack};
    }
    .expressions {
      gap: 13px;
      .expression-value {
        cursor: pointer;
        height: ${doubleSpacing}px;
        color: ${almostBlack};
        border-radius: ${quarterSpacing}px;
        border: 1px solid ${dividerMedium};
        &.selected {
          font-weight: 600;
          border: 2px solid ${novoEdBlue};
          background-color: rgba(14, 114, 237, 0.05);
        }
      }
    }
  }
`;

type MatchingRuleRowProps = {
  rule: AutoMatchingRule;
  onDelete: (ruleId: number) => void;
};

const getQuestionText = (question: MentorshipProgramProfileQuestion) => {
  const { isNovoedQuestion, novoedQuestionName, questionType, profileQuestion } = question;
  let questionText = '';
  if (isNovoedQuestion) {
    questionText = getName(novoedQuestionName, t);
  } else {
    if (
      questionType === QuestionType.QUESTION
      && (profileQuestion as ProgramLevelQuestion).questionList
    ) {
      const { questionList } = profileQuestion as ProgramLevelQuestion;
      questionText = questionList?.questionText;
    }
    if (
      questionType === QuestionType.PROFILE_SETTING
      && (profileQuestion as OrgLevelQuestion)?.profileSetting
    ) {
      const { profileSetting } = profileQuestion as OrgLevelQuestion;
      questionText = profileSetting?.name;
    }
  }
  return questionText;
};

const MatchingRuleRow = ({ rule, onDelete }: MatchingRuleRowProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const expressions = {
    [Expression.SIMILAR]: t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.LIST.EXPRESSION.TYPES.SIMILAR(),
    [Expression.DIFFERENT]: t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.LIST.EXPRESSION.TYPES.DIFFERENT(),
  };
  const {
    id: ruleId,
    expression,
    importanceLevel,
    mentorshipProgramProfileQuestion,
  } = rule;
  const questionText = getQuestionText(mentorshipProgramProfileQuestion);

  const handleChange = autoMatchingRule => {
    dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
    wrapThunkAction(
      dispatch(
        updateMentorshipProgramAutoMatchingRule({
          mentorshipProgramId,
          ruleId,
          autoMatchingRule,
        }),
      ),
    )
      .then(() => {
        dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
  };

  return (
    <div css={styles} className='p-4'>
      <div className='d-flex flex-column matching-rule-details'>
        <div className='question-text pl-2 pr-2 bold text-large-body align-content-center'>
          {questionText}
        </div>
        <div className='expression-description'>
          {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.LIST.EXPRESSION.DESCRIPTION()}
        </div>
        <div className='d-flex expressions'>
          {Object.entries(expressions).map(([key, value]) => (
            <ClickableContainer
              key={key}
              onClick={() => handleChange({ expression: key })}
              className={`pl-2 pr-2 text-medium align-items-center expression-value ${
                expression === key ? 'selected' : ''
              }`}
              data-qa={config.pendo.athena.mentorshipProgram.settings.matchingRules.updateMatchingRuleExpressionType}
            >
              {value}
            </ClickableContainer>
          ))}
        </div>
      </div>
      <ImportanceLevelSlider level={importanceLevel} onChange={handleChange} />
      <OptionsMenu ruleId={ruleId} onDelete={onDelete} />
    </div>
  );
};

export default MatchingRuleRow;
