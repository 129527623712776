import React from 'react';
import { css } from '@emotion/react';
import Truncate from 'react-truncate';

import t from 'react-translate';
import type { RowProps } from 'shared/components/nv-table';
import Avatar from 'athena/components/mentoring-program/avatar';
import { hexToRgbaString, kelp } from 'styles/global_defaults/colors';
import { almostWhite, darkGray, shadedWhite, paleGray, dunes70 } from 'athena/styles/colors';
import { commonStyles } from 'athena/components/mentoring-program/participants/participant-row/styles';
import type { CellProps } from 'athena/components/mentoring-program/participants/participant-row/types';
import OptionsCell from 'athena/components/mentoring-program/participants/participant-row/options-cell';
import ConnectionsCell from 'athena/components/mentoring-program/participants/participant-row/connections-cell';
import { MentoringProgramEnrollment, MentorshipStatus } from 'redux/schemas/models/mentoring-program-enrollments';

export const NameCell = (props: CellProps) => {
  const { cell, className } = props;

  const value = cell.getValue();
  const { row: { original: { role } } } = cell;

  const styles = css`
    ${commonStyles};

    .picture {
      flex-shrink: 0;
    }

    .right-container {
      flex: 1;
      min-width: 0;
    }

    .email {
      color: ${darkGray};
    }
  `;

  return (
    <div className={`d-flex align-items-center ${className}`} css={styles}>
      <Avatar user={value} role={role?.name} className='picture mr-2' />
      <div className='right-container d-flex flex-column justify-content-center align-items-stretch'>
        <div className='truncated-container'>
          <Truncate
            lines={1}
            ellipsis='...'
            className='text-medium bold'
          >
            {value.firstName} {value.lastName}
          </Truncate>
        </div>
        <div>
          <Truncate
            className='text-small email'
            lines={3}
            ellipsis='...'
          >
            {value.email}
          </Truncate>
        </div>
      </div>
    </div>
  );
};

const StatusCell = (props: CellProps) => {
  const { cell, className } = props;

  const { row: { original } } = cell;

  const getStatusColor = () => {
    switch (original.mentorshipStatus) {
      case MentorshipStatus.MATCHED:
        return hexToRgbaString(kelp, 0.5);
      case MentorshipStatus.UNMATCHED:
        return paleGray;
      case MentorshipStatus.MATCHED_WITH_AVAILABILITY:
        return dunes70;
      default:
        return undefined;
    }
  };

  const getStatus = () => {
    switch (original.mentorshipStatus) {
      case MentorshipStatus.MATCHED:
        return t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANT_ROW.MATCHED();
      case MentorshipStatus.UNMATCHED:
        return t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANT_ROW.UNMATCHED();
      case MentorshipStatus.MATCHED_WITH_AVAILABILITY:
        return t.MENTORING_PROGRAMS.PARTICIPANTS.PARTICIPANT_ROW.MATCHED_WITH_AVAILABILITY();
      default:
        return undefined;
    }
  };

  const styles = css`
    ${commonStyles};

    .indicator {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background-color: ${getStatusColor()};
    }

    .status {
      color: ${darkGray};
    }
  `;

  return (
    <div className={`d-flex align-items-center ${className}`} css={styles}>
      <div className='indicator mr-2' />
      <div className='text-medium status'>
        {getStatus()}
      </div>
    </div>
  );
};

const cells = {
  name: NameCell,
  status: StatusCell,
  connections: ConnectionsCell,
  options: OptionsCell,
};

const ParticipantRow = (props: RowProps<MentoringProgramEnrollment>) => {
  const { row, className, getCellStyle } = props;

  const styles = css`
    border-bottom: 1px solid ${shadedWhite};

    &:hover {
      background-color: ${almostWhite};

      .options-target {
        opacity: 1;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }
  `;

  return (
    <div css={styles} className={className}>
      {row.getVisibleCells().map((cell, index) => {
        const CellComponent = cells[cell.column.id];

        return (
          <CellComponent css={getCellStyle(index)} cell={cell} key={cell.id} />
        );
      })}
    </div>
  );
};

export default ParticipantRow;

