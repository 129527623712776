/* eslint-disable no-shadow */
/* eslint-disable global-require */
import 'core-js';

// Set up our old-school libraries that insist on being used from the global window object
import _ from 'underscore';
import * as humps from 'humps';
import * as bignumber from 'bignumber.js';

// import * as viewportUnitsBuggyfill from 'viewport-units-buggyfill';

// https://github.com/babel/babel/issues/9849#issuecomment-487040428
import 'regenerator-runtime/runtime.js';

import angular from 'angular';
// Import our shared .json file. Note that .json imports work by default in modern Webpack
// (which we use) https://webpack.js.org/loaders/json-loader/. The JSON contents are exported
// as a javascript object in the 'default' export
import * as Sentry from '@sentry/browser';
import store from 'redux/store';
import { config } from '../config/config.json';

import { initSentry, sentryEnabled } from './shared/sentry';
import pendoTrack from './shared/pendo';


import 'angular-translate';
import 'air-datepicker/dist/js/datepicker';
import 'air-datepicker/dist/js/i18n/datepicker.es';
import 'air-datepicker/dist/js/i18n/datepicker.en';
import 'air-datepicker/dist/js/i18n/datepicker.fr';
import 'air-datepicker/dist/js/i18n/datepicker.pt';
import 'air-datepicker/dist/js/i18n/datepicker.zh';
import 'air-datepicker/dist/js/i18n/datepicker.de';
import 'air-datepicker/dist/js/i18n/datepicker.pt-BR';
import 'air-datepicker/dist/js/i18n/datepicker.ar';
import 'air-datepicker/dist/js/i18n/datepicker.pl';
import 'air-datepicker/dist/js/i18n/datepicker.nl';
import 'air-datepicker/dist/js/i18n/datepicker.ro';
import 'angular-animate';
import 'angular-aria';
import 'angular-ui-bootstrap';
import 'angular-clipboard';
import 'angular-cookies';
import 'angular-drag-and-drop-lists';
import 'angular-inview';
import 'angular-mocks';
import 'angular-moment';
import 'angular-recaptcha';
import 'angular-resource';
import 'angular-route';
import 'angular-sanitize';
import 'angular-scroll';
import 'angular-slick-carousel';
import 'angular-socialshare';
import 'angular-touch';
import 'angular-translate-interpolation-messageformat';
import 'angular-ui-indeterminate';
import 'angular-ui-router';
import 'angular-ui-scroll';
import 'angular-ui-sortable';
import 'angular-validation-match';
import 'angularjs-slider';

import 'codemirror';

import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/url.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/js/languages/es';
import 'froala-editor/js/languages/fr';
import 'froala-editor/js/languages/pt_pt';
import 'froala-editor/js/languages/pt_br';
import 'froala-editor/js/languages/zh_cn';
import 'froala-editor/js/languages/ja';
import 'froala-editor/js/languages/ko';
import 'froala-editor/js/languages/ru';
import 'froala-editor/js/languages/de';
import 'froala-editor/js/languages/ar';
import 'froala-editor/js/languages/he';
import 'froala-editor/js/languages/pl';
import 'froala-editor/js/languages/it';
import 'froala-editor/js/languages/nl';
import 'froala-editor/js/languages/sv';
import 'froala-editor/js/languages/ro';

// Import the local mentio library instead of pulling from NPM
import './froala/lib/mentio';
import './froala/plugins/plugin_defaults';
import './froala/plugins/paragraph_style';
import './froala/plugins/link';
import './froala/plugins/image';
import './froala/plugins/colors';
import './froala/plugins/align';

import 'jquery-ui';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery.actual';
import 'messageformat';
import 'moment';
import 'moment-timezone';
import 'ng-file-upload';
import 'ng-stats';
import 'offline';
import 'pusher-js';
import 'pusher-angular';
import 'slick-carousel';
import 'srcdoc-polyfill';
// the following modules come from 'ui-router-extras' -  we are intentionally excluding 'ui-router-extras/release/modular/ct-ui-router-extras.future'
// we aren't using future, but also https://github.com/christopherthielen/ui-router-extras/issues/220
import 'ui-router-extras/release/modular/ct-ui-router-extras.core';
import 'ui-router-extras/release/modular/ct-ui-router-extras.sticky';
import 'ui-router-extras/release/modular/ct-ui-router-extras.dsr';
import 'ui-router-extras/release/modular/ct-ui-router-extras.previous';
import 'ui-router-extras/release/modular/ct-ui-router-extras.statevis';
import 'ui-router-extras/release/modular/ct-ui-router-extras.transition';

import './shared/yup-validations';

// Our controllers, services, etc
import AccountBasicsCtrl from './account_settings/controllers/account-basics-controller';
import AccountSettingsNavCtrl from './account_settings/controllers/account-settings-nav-controller';
import AdminAddMembersModalCtrl from './teams/controllers/admin-add-members-modal-ctrl';
import AdminControlsCtrl from './learner_profiles/controllers/admin-controls-controller';
import AggregatePeerReviewModel from './evaluations/services/aggregate-peer-review-model';
import AlertMessages from './shared/services/alert-messages';
import AnchorModifier from './shared/services/anchor-modifier';
import AnnouncementFormModalCtrl from './announcements/controllers/announcement-form-modal-controller';
import AnnouncementModel from './announcements/services/announcement-model';
import AnnouncementViewEmailModalCtrl from './announcements/controllers/announcement-view-email-modal-controller';
import AnnouncementsCourseListCtrl from './announcements/controllers/announcements-course-list-controller';
import AnnouncementsCtrl from './announcements/controllers/announcements-controller';
import AnnouncementsForCoursePreviewCtrl from './announcements/controllers/announcements-for-course-preview-controller';
import AnnouncementsManager from './announcements/services/announcements-manager';
import AnnouncementsModalCtrl from './announcements/controllers/announcements-modal-controller';
import AnnouncementsPreviewCtrl from './announcements/controllers/announcements-preview-controller';
import AnnouncementsResources from './announcements/services/announcements-resources';
import AssignmentIntroModalController from './submissions/controllers/assignment-intro-modal-controller';
import AttachModalResolvesToVmCtrl from './shared/controllers/attach-modal-resolves-to-vm-ctrl';
import AttachmentLectureComponentModel from './lecture_pages/services/components/attachment-lecture-component-model';
import ImageLectureComponentModel from './lecture_pages/services/components/image-lecture-component-model';
import AccordionLectureComponentModel from './lecture_pages/services/components/accordion-lecture-component-model';
import StyledLinkLectureComponentModel from './lecture_pages/services/components/styled-link-lecture-component-modal';
import ProfileCompletionComponentModel from './lecture_pages/services/components/profile-completion-component-model';
import AudioListLectureComponentModel from './lecture_pages/services/components/audio-list-lecture-component-model';
import AuthenticationInterceptor from './shared/services/authentication-interceptor';
import AuthenticationsCtrl from './users/controllers/authentications-ctrl';
import BlurbBackgroundImageLectureComponentModel from './lecture_pages/services/components/content_templates/blurb-background-image-component-model';
import BlurbSideImageLectureComponentModel from './lecture_pages/services/components/content_templates/blurb-side-image-lecture-component-model';
import BlurbTopImageLectureComponentModel from './lecture_pages/services/components/content_templates/blurb-top-image-lecture-component-model';
import BulkEditDeadlineCtrl from './timelines/controllers/bulk-edit-deadline-controller';
import BulkEditReleaseDatesCtrl from './timelines/controllers/bulk-edit-release-dates-controller';
import BookmarkModel from './bookmarks/services/bookmark-model';
import BookmarkResources from './bookmarks/services/bookmark-resources';
import CopyToOfferingModalCtrl from './timelines/controllers/copy-to-offering-modal-controller';
import ChangeCaseInterceptor, { shouldChangeCase } from './shared/services/change-case-interceptor';
import ChangeInstitutionCtrl from './users/controllers/change-institution-ctrl';
import CommentModel from './discussions/services/comment-model';
import CommentsResources from './discussions/services/comments-resource';
import ConfettiAnimation from './shared/services/confetti-animation';
import ConfigureSSOModalCtrl from './institutions/controllers/configure-sso-modal-controller';
import ConfigureTOSModalCtrl from './institutions/controllers/configure-tos-modal-controller';
import ConfigureZoomModalCtrl from './institutions/controllers/configure-zoom-modal-controller';
import ConfigureDegreedModalCtrl from './institutions/controllers/configure-degreed-modal-controller';
import ConfigureMsTeamsBotIntegrationModalCtrl from './institutions/controllers/configure-ms-bot-integration-controller';
import ConfigureLmsModalCtrl from './institutions/controllers/configure-lms-modal-controller';
import ConfirmationOverlays from './shared/services/confirmation-overlays';
import ContentTemplateBaseModel from './lecture_pages/services/components/content_templates/content-template-base-model';
import ContentTemplateEditFormModalCtrl from './lecture_pages/controllers/content-template-edit-form-modal-controller';
import ConversationModel from './conversations/services/conversation-model';
import ConversationsManager from './conversations/services/conversations-manager';
import ConversationsPreviewCtrl from './conversations/controllers/conversations-preview-controller';
import ConversationsResource from './conversations/services/conversations-resources';
import CountdownTimerManager from './shared/services/countdown-timer-manager';
import CountdownTimerModel from './shared/services/countdown-timer-model';
import CourseFormBaseCtrl from './courses/controllers/course-form-base-ctrl.jsx';
import CourseHomeBaseCtrl from './course_home/controllers/course-home-base-controller';
import CourseHomeCtrl from './course_home/controllers/course-home-controller';
import CourseHomeManager from './course_home/services/course-home-manager';
import CourseHomeModel from './course_home/services/course-home-model';
import CourseHomeResources from './course_home/services/course-home-resource';
import CourseHomeRhsBaseCtrl from './course_home/controllers/course-home-rhs-base-controller';
import CourseHomeRhsCtrl from './course_home/controllers/course-home-rhs-controller';
import CourseHomeRhsManager from './course_home/services/course-home-rhs-manager';
import CourseModel from './courses/services/course-model';
import CourseRoleModel from './institutions/services/course-role-model';
import CourseRolesManager from './institutions/services/course-roles-manager';
import CourseRolesResource from './institutions/services/course-roles-resource';
import CoursesManager from './courses/services/courses-manager';
import CoursesResource from './courses/services/courses-resource';
import CreateContentDownloadCtrl from './custom_downloads/controllers/create-content-download-controller';
import CreateReportsCtrl from './custom_downloads/controllers/create-reports-controller';
import CurrentCourseManager from './courses/services/current-course-manager';
import CurrentPermissionsManager from './shared/services/current-permissions-manager';
import CurrentUserManager from './users/services/current-user-manager';
import CustomDownloadsCtrl from './custom_downloads/controllers/custom-downloads-controller';
import CustomDownloadsManager from './custom_downloads/services/custom-downloads-manager';
import CustomDownloadsModel from './custom_downloads/services/custom-downloads-model';
import CustomDownloadsResources from './custom_downloads/services/custom-downloads-resource';
import DashboardCtrl from './dashboard/controllers/dashboard-controller';
import DashboardManager from './dashboard/services/dashboard-manager';
import DashboardModel from './dashboard/services/dashboard-model';
import DashboardResources from './dashboard/services/dashboard-resource';
import DashboardHeaderController from './dashboard/controllers/dashboard-header-controller';
import MentorDashboard from './org_mentor/controllers/mentor-dashboard-controller';
import CourseAdminDashboardCtrl from './course_admin_dashboard/controllers/course-admin-dashboard';
import LearningCatalogCtrl from './dashboard/controllers/learning-catalog-controller';
import DataDownloadsListCtrl from './custom_downloads/controllers/data-downloads-list-controller';
import DirectLinkCtrl from './discussions/controllers/direct-link-controller';
import DirectoryManagerInterface from './directories/services/directory-manager-interface';
import DiscussionFormModalCtrl from './lecture_pages/controllers/discussion-form-modal-controller';
import DiscussionLectureComponentModel from './lecture_pages/services/components/discussion-lecture-component-model';
import TeamDiscussionLectureComponentModel from './lecture_pages/services/components/team-discussion-lecture-component-model';
import AbstractDiscussionLectureComponentModel from './lecture_pages/services/components/abstract-discussion-lecture-component-model';
import PollLectureComponentModel from './lecture_pages/services/components/poll-lecture-component-model';
import DiscussionSortFilterCtrl from './discussions/controllers/discussions-sort-filter-controller';
import DiscussionsIndexCtrl from './discussions/controllers/discussions-index-controller';
import DiscussionsManager from './discussions/services/discussions-manager';
import DiscussionsResources from './discussions/services/discussions-resource';
import DiscussionsRhsCtrl from './discussions/controllers/discussions-rhs-controller';
import DocumentWorkspacePostModalInstanceController from './team_workspace/controllers/document-workspace-post-modal-instance-controller';
import DownloadDetailsCtrl from './custom_downloads/controllers/download-details-controller';
import EditCourseCtrl from './courses/controllers/edit-course-ctrl';
import EmailPreferencesCtrl from './account_settings/controllers/email-preferences-controller';
import EmbedLectureComponentModel from './lecture_pages/services/components/embed-lecture-component-model';
import WebLinkLectureComponentModel from './lecture_pages/services/components/web-link-lecture-component-model';
import WebEmbedLectureComponentModel from './lecture_pages/services/components/web-embed-lecture-component-model';
import EvaluationsFormBaseCtrl from './evaluations/controllers/evaluations-form-base-controller';
import EvaluationsFormCtrl from './evaluations/controllers/evaluations-form-controller';
import EvaluationsTaskManager from './evaluations/services/evaluations-task-manager';
import EvaluationsTaskModel from './evaluations/services/evaluations-task-model';
import EvaluationsTaskResources from './evaluations/services/evaluations-task-resource';
import ExerciseEditFormModalCtrl from './lecture_pages/controllers/exercise-edit-form-modal-controller';
import ExerciseLectureComponentModel from './lecture_pages/services/components/exercise-lecture-component-model.jsx';
import ExerciseModel from './exercises/services/exercise-model';
import ExerciseShareSubmissionModalCtrl from './lecture_pages/controllers/exercise-share-submission-modal-controller.js';
import ExerciseShareWithTeamModalCtrl from './lecture_pages/controllers/exercise-share-with-team-modal-controller.js';
import ExerciseShowBaseCtrl from './exercises/controllers/exercise-show-base-controller';
import ExercisesManager from './exercises/services/exercises-manager';
import ExercisesResources from './exercises/services/exercises-resource';
import ExerciseSkillsRatingLectureComponentModel from './lecture_pages/services/components/exercise-skills-rating-component-model';
import ExternalToolLectureComponentModel from './lecture_pages/services/components/external-tool-lecture-component-model';
import FeedbackEditFormModalCtrl from './lecture_pages/controllers/feedback-edit-form-modal-controller';
import FilterCoursesManager from './courses/services/filter-courses-manager';
import FlaggingModel from './submissions/services/flagging-model';
import FlyerCtrl from './flyers/controllers/flyer-ctrl';
import FlyOutPanelManager from './shared/services/flyout-panel-manager';
import FlyoutModalManager from './shared/services/flyout-modal-manager';
import ContentSearchFlyoutCtrl from './content_search/controllers/content-search-flyout-controller';
import BookmarksFlyoutCtrl from './bookmarks/controllers/bookmarks-flyout-controller';
import AdminToolsFlyoutCtrl from './lhs/controllers/AdminToolsFlyoutCtrl';
import CourseMenteesFlyoutCtrl from './lhs/controllers/CourseMenteesFlyoutCtrl';
import MyLearningFlyoutCtrl from './lhs/controllers/MyLearningFlyoutCtrl';
import TeamsGroupsFlyoutCtrl from './lhs/controllers/TeamsGroupsFlyoutCtrl';
import FramedFroalaCtrl from './froala/controllers/framed-froala-controller';
import FroalaFilePopoverCtrl from './froala/controllers/froala-file-popover-controller';
import GroupDirectoryMainController from './group_directory/controllers/group-directory-main-controller';
import GroupDirectoryManager from './group_directory/services/group-directory-manager';
import GroupDirectorySortFilterController from './group_directory/controllers/group-directory-sort-filter-controller';
import GroupFormationLectureComponentModel from './lecture_pages/services/components/group-formation-lecture-component-model';
import HeaderLectureComponentModel from './lecture_pages/services/components/content_templates/header-lecture-component-model';
import HighlightPostModalInstanceCtrl from './discussions/controllers/highlight-post-modal-instance-controller';
import IndividualSubmissionBaseCtrl from './submissions/controllers/individual-submission-base-controller';
import IndividualSubmissionCtrl from './submissions/controllers/individual-submission-controller';
import IndividualSubmissionPaginationCtrl from './submissions/controllers/individual-submission-pagination-controller';
import IndividualVideoEditCtrl from './lecture_pages/controllers/individual-video-edit-controller';
import InformalFeedbackModalCtrl from './submissions/controllers/informal-feedback-modal-controller';
import InstitutionAdminModel from './institutions/services/institution-admin-model';
import InstitutionAdminsResource from './institutions/services/institution-admins-resource';
import InstitutionAdvancedSettingsCtrl from './institutions/controllers/institution-advanced-settings-ctrl';
import InstitutionEditEmailTemplateCtrl from './institutions/controllers/institution-edit-email-template-ctrl';
import InstitutionEmailTemplateCtrl from './institutions/controllers/institution-email-template-ctrl';
import LearningJourneysCtrl from './learning_journeys/controllers/learning-journeys-ctrl';
import LearningJourneyCtrl from './learning_journeys/controllers/learning-journey-ctrl';
import InstitutionRolesCtrl from './institutions/controllers/institution-roles-ctrl';
import InstitutionsManager from './institutions/services/institutions-manager';
import InstitutionsResource from './institutions/services/institutions-resource';
import InteroperabilityRoutes from './shared/services/interoperability-routes';
import InvitationAndRequestModel from './invitations_and_requests/services/invitation-and-request-model';
import InvitationsAndRequestsCtrl from './invitations_and_requests/controllers/invitations-and-requests-controller';
import InvitationsAndRequestsManager from './invitations_and_requests/services/invitations-and-requests-manager';
import InvitationsAndRequestsResources from './invitations_and_requests/services/invitations-and-requests-resources';
import L3EditReportHeaderCtrl from './shared/controllers/l3-edit-report-header-ctrl';
import L3HeaderCtrl from './shared/controllers/l3-header-ctrl';
import OrgL3HeaderCtrl from './shared/controllers/org-l3-header';
import L3ShowExerciseHeaderCtrl from './shared/controllers/l3-show-exercise-header-ctrl';
import L3ShowReportHeaderCtrl from './shared/controllers/l3-show-report-header-ctrl';
import LTILectureComponentModel from './lecture_pages/services/components/lti-lecture-component-model';
import LangsAndTZController from './account_settings/controllers/langs-and-tz-controller';
import LanguageModalCtrl from './shared/controllers/language-modal-controller';
import LeaderboardCtrl from './leaderboard/controllers/leaderboard-controller';
import LeaderboardManager from './leaderboard/services/leaderboard-manager';
import LeaderboardModel from './leaderboard/services/leaderboard-model';
import LeaderboardResources from './leaderboard/services/leaderboard-resource';
import LearnerDirectoryAllFiltersModalCtrl from './discussions/controllers/learner-directory-all-filters-modal-controller';
import LearnerDirectoryMainController from './learner_directory/controllers/learner-directory-main-controller';
import LearnerDirectoryManager from './learner_directory/services/learner-directory-manager';
import LearnerDirectorySortFilterController from './learner_directory/controllers/learner-directory-sort-filter-controller';
import LearnerProfileBaseCtrl from './learner_profiles/controllers/learner-profile-base-controller';
import LearnerProfileCtrl from './learner_profiles/controllers/learner-profile-controller';
import LearnerProfileManager from './learner_profiles/services/learner-profile-manager';
import LearnerProfileModel from './learner_profiles/services/learner-profile-model';
import LearnerProfilePaginationCtrl from './learner_profiles/controllers/learner-profile-pagination-controller';
import LearnerProfileResources from './learner_profiles/services/learner-profile-resources';
import LearnerProgressDashboardCtrl from './learner_progress/controllers/learner-progress-dashboard-ctrl';
import LeavePracticeActivityCtrl from './lecture_pages/controllers/leave-practice-activity-controller';
import LectureComponentBaseCtrl from './lecture_pages/controllers/lecture-component-base-controller';
import LectureComponentBaseModel from './lecture_pages/services/components/lecture-component-base-model';
import L4Modal from './shared/services/l4-modal';
import LectureComponentModalCtrl from './lecture_pages/controllers/lecture-component-modal-controller';
import LectureComponentsHelper from './lecture_pages/services/components/lecture-components-helper';
import LecturePageBaseModel from './shared/services/lecture-page-base-model';
import LecturePageReactCtrl from './lecture_pages/controllers/lecture-page-react-controller';
import LecturePageManager from './lecture_pages/services/lecture-page-manager';
import ReactLecturePageContext from './lecture_pages/services/react-lecture-page-context';
import ReactTimelineService from './timelines/services/react-timeline-service';
import LecturePageModel from './lecture_pages/services/lecture-page-model';
import LectureVideoListResources from './lecture_videos/services/lecture-video-lists-resources';
import LectureVideoModel from './lecture_videos/services/lecture-video-model';
import LectureVideoResources from './lecture_videos/services/lecture-video-resources';
import LectureVideosBaseCtrl from './lecture_videos/controllers/lecture-videos-base-controller';
import LectureVideosCtrl from './lecture_videos/controllers/lecture-videos-controller';
import LectureVideosManager from './lecture_videos/services/lecture-videos-manager';
import LeftNavUserCtrl from './layouts/controllers/left-nav-user-controller';
import LhsCoursesCtrl from './courses/controllers/lhs-courses-ctrl';
import LhsPracticeRoomCtrl from './practice_room/controllers/lhs-ctrl';
import LikertScaleQuestion from './quizzes/services/questions/likert-scale-question';
import LineDividerLectureComponentModel from './lecture_pages/services/components/content_templates/line-divider-lecture-component-model';
import LiveSessionLectureComponentModel from './lecture_pages/services/live-session-lecture-component-model';
import LiveSessionModel from './live_sessions/services/live-session-model';
import MainContentCtrl from './layouts/controllers/main-content-controller';
import MainGridCtrl from './layouts/controllers/main-grid-controller';
import MainGridHamburgerMenuCtrl from './shared/controllers/main-grid-hamburger-menu-ctrl';
import ManageCourseRolesCtrl from './institutions/controllers/manage-course-roles';
import ManageTeamGroupReactCtrl from './manage_team_group/controllers/react-ctrl.jsx';
import ManualAddUserModalController from './user_management/controllers/manual-add-user-modal-controller';
import MathjaxLoader from './shared/services/mathjax-loader';
import MediaUploadModalInstanceCtrl from './froala/controllers/media-upload-modal-instance-controller';
import MeetingRsvpModalInstanceController from './team_workspace/controllers/meeting-rsvp-modal-instance-controller';
import LectureLightHeaderCtrl from './lecture_pages/controllers/lecture-light-header-controller';
import MembershipRequestModel from './teams/services/membership-request-model';
import MembershipRequestResource from './teams/services/membership-request-resource';
import MenteesManager from './mentors/services/mentees-manager';
import MenteesPageBaseController from './mentors/controllers/mentees-page-base-controller';
import MenteesPageController from './mentors/controllers/mentees-page-controller';
import MenteesPageHeaderController from './mentors/controllers/mentees-page-header-controller';
import MenteesResources from './mentors/services/mentees-resources';
import MentionablesModel from './shared/services/mentionables-manager';
import MessageModel from './conversations/services/message-model';
import MetaTagsService from './shared/services/meta-tags-service';
import ModalFocusService from './shared/services/modal-focus-service';
import MultipleChoiceMultipleAnswerQuestion from './quizzes/services/questions/multiple-choice-multiple-answer-question';
import MultipleChoiceQuestion from './quizzes/services/questions/multiple-choice-question';
import NewCourseCtrl from './courses/controllers/new-course-ctrl';
import NewPostModalCtrl from './discussions/controllers/new-post-modal-controller';
import NewTopicModalCtrl from './discussions/controllers/new-topic-modal-controller';
import NotificationModel from './notifications/services/notification-model';
import NotificationsCtrl from './notifications/controllers/notifications-controller';
import NotificationsManager from './notifications/services/notifications-manager';
import NotificationsResources from './notifications/services/notifications-resources';
import NumberQuestion from './quizzes/services/questions/number-question';
import NvModalCtrl from './shared/controllers/nv-modal-ctrl';
import OfflineManager from './shared/services/offline-manager';
import OrgMentorCtrl from './org_mentor/controllers/org-mentor-ctrl';
import PageLevelManager from './shared/services/page-level-manager';
import PasswordsResource from './users/services/passwords-resource';
import PeerEvaluationLectureComponentModel from './lecture_pages/services/components/peer-evaluation-lecture-component-model';
import PeerReviewModel from './evaluations/services/peer-review-model';
import PeerReviewsBaseCtrl from './evaluations/controllers/peer-reviews-base-controller';
import PointsBreakdownModalController from './shared/controllers/points-breakdown-modal-controller';
import PointsModalCtrl from './shared/controllers/points-modal-controller.jsx';
import PostModel from './discussions/services/post-model';
import PostsResources from './discussions/services/posts-resource';
import PracticeRoomFlyoutReactCtrl from './practice_room/controllers/flyout-react-ctrl.jsx';
import PracticeRoomReactCtrl from './practice_room/controllers/react-ctrl.jsx';
import PrivacyPolicyModalCtrl from './shared/controllers/privacy-policy-modal-controller';
import PrivatePeerEvaluationLectureComponentModel from './lecture_pages/services/components/private-peer-evaluation-lecture-component-model';
import ProgramHomeController from './course_home/controllers/program-home-controller';
import PubSubDiscussions from './discussions/services/pub-sub-discussions';
import PublicPeerEvaluationLectureComponentModel from './lecture_pages/services/components/public-peer-evaluation-lecture-component-model';
import VideoPracticeSkillsRatingLectureComponentModel from './lecture_pages/services/components/video-practice-skills-rating-lecture-component';
import PusherChannels from './shared/services/pusher-channels';
import PusherManager from './shared/services/pusher-manager';
import QuizAnswerFeedbackCtrl from './quizzes/controllers/quiz-answer-feedback-controller';
import QuizFormBaseCtrl from './quizzes/controllers/quiz-form-base-controller';
import QuizFormCtrl from './quizzes/controllers/quiz-form-controller';
import QuizLectureComponentModel from './lecture_pages/services/components/quiz-lecture-component-model';
import QuizModel from './quizzes/services/quiz-model';
import QuizzesManager from './quizzes/services/quizzes-manager';
import QuizzesResources from './quizzes/services/quizzes-resource';
import RailsRoutesFactory from './shared/services/rails-routes';
import ReadOnlyQuestion from './quizzes/services/questions/read-only-question';
import RepliesResources from './discussions/services/replies-resource';
import ReplyModel from './discussions/services/reply-model';
import ReportFormHeaderCtrl from './reports/controllers/report-form-header-ctrl';
import ReportModel from './reports/services/report-model';
import ReportSubmittedCtrl from './reports/controllers/report-submitted-ctrl';
import ReportsManager from './reports/services/reports-manager';
import ReportsResources from './reports/services/reports-resources';
import RequestPasswordResetCtrl from './users/controllers/request-password-reset-ctrl';
import ResetPasswordCtrl from './users/controllers/reset-password-ctrl';
import RichTextComposerResource from './froala/services/rich-text-composer-resource';
import RichTextLectureComponentModel from './lecture_pages/services/components/rich-text-lecture-component-model';
import RichTextQuestion from './quizzes/services/questions/rich-text-question';
import RolesService from './institutions/services/roles-service';
import S3UploadWrapper from './shared/services/s3-upload-wrapper';
import ScormLectureComponentModel from './lecture_pages/services/components/scorm-lecture-component-model';
import ScrollFocusConnectorFactory from './discussions/services/nv-scroll-focus-connector';
import SendMessageModalCtrl from './learner_profiles/controllers/send-message-modal-controller';
import ShortTextQuestion from './quizzes/services/questions/short-text-question';
import StateManager from './shared/services/state-manager';
import StatementBaseCtrl from './statements/controllers/statement-base-ctrl';
import StatementCtrl from './statements/controllers/statement-ctrl';
import StatementModel from './statements/services/statement-model';
import StatementsManager from './statements/services/statements-manager';
import StatementsResources from './statements/services/statements-resources';
import StickyHeaderFooterCtrl from './shared/controllers/nv-sticky-header-footer-controller';
import FlyOutPanelController from './shared/controllers/nv-flyout-panel-controller';
import StyleGuideCodeCtrl from './style-guide/controllers/style-guide-code-controller';
import StyleGuideCtrl from './style-guide/controllers/style-guide-controller';
import StyleGuideReactCtrl from './style-guide/controllers/style-guide-react-controller';
import StyleGuideFroalaCtrl from './style-guide/controllers/style-guide-froala-controller';
import SubmissionGalleryCtrl from './submissions/controllers/submission-gallery-controller';
import SubmissionSortFilterCtrl from './submissions/controllers/submission-sort-filter-controller';
import SubmissionsDiscoveryEditFormModalCtrl from './lecture_pages/controllers/submissions-discovery-edit-form-modal-controller';
import SubmissionsDiscoveryLectureComponentModel from './lecture_pages/services/components/submissions-discovery-lecture-component-model';
import SurveyLectureComponentModel from './lecture_pages/services/components/survey-lecture-component-model';
import SkillTagsCtrl from './skill_tags/controllers/skill-tags-ctrl';
import ContentLibraryCtrl from './content-library/controllers/content-library-ctrl';
import AnalyticsDashboardCtrl from './analytics_dashboard/controllers/analytics-ctrl';
import TableNumberQuestion from './quizzes/services/questions/table-number-question';
import TableShortTextQuestion from './quizzes/services/questions/table-short-text-question';
import TeachingTeamDirectoryMainController from './teaching_team_directory/controllers/teaching-team-directory-main-controller';
import TeachingTeamDirectoryManager from './teaching_team_directory/services/teaching-team-directory-manager';
import TeachingTeamDirectorySortFilterController from './teaching_team_directory/controllers/teaching-team-directory-sort-filter-controller';
import TeamDirectoryMainController from './team_directory/controllers/team-directory-main-controller';
import TeamDirectoryManager from './team_directory/services/team-directory-manager';
import TeamDirectorySortFilterController from './team_directory/controllers/team-directory-sort-filter-controller';
import TeamFormModalCtrl from './teams/controllers/team-form-modal-ctrl';
import TeamFormationLectureComponentModel from './lecture_pages/services/components/team-formation-lecture-component-model';
import GrantLateSubmissionPermitCtrl from './learner_progress/controllers/grant-late-submission-permit-ctrl.js';
import TeamManager from './teams/services/team-manager';
import TeamMemberCardCtrl from './teams/controllers/team-member-card-ctrl';
import TeamMembershipActionsCtrl from './teams/controllers/team-membership-actions-ctrl';
import TeamMembershipModel from './teams/services/team-membership-model';
import TeamMembershipsResource from './teams/services/team-memberships-resource';
import TeamModel from './teams/services/team-model';
import TeamProfileBaseCtrl from './teams/controllers/team-profile-base-ctrl';
import TeamProfileCtrl from './teams/controllers/team-profile-ctrl';
import TeamProfilePaginationCtrl from './teams/controllers/team-profile-pagination-controller';
import TeamProfileTeamMembersCtrl from './teams/controllers/team-profile-team-members-ctrl';
import TeamSetModel from './teams/services/team-set-model';
import TeamSetsResources from './teams/services/team-sets-resources';
import TeamWorkspaceBaseController from './team_workspace/controllers/team-workspace-base-controller';
import TeamWorkspaceDirectLinkController from './team_workspace/controllers/team-workspace-direct-link-controller';
import TeamWorkspaceMainController from './team_workspace/controllers/team-workspace-main-controller';
import TeamRecentPostsCtrl from './team_workspace/controllers/team-recent-posts-controller';
import TeamRecentAssignmentsCtrl from './team_workspace/controllers/team-recent-assignments-controller';
import TeamAddMembersCtrl from './teams/controllers/team-add-members-modal.js';
import TeamWorkspaceManager from './team_workspace/services/team-workspace-manager';
import TeamWorkspaceMembersBaseController from './team_workspace/controllers/team-workspace-members-base-controller';
import TeamWorkspaceMembersController from './team_workspace/controllers/team-workspace-members-controller';
import TeamWorkspaceModel from './team_workspace/services/team-workspace-model';
import TeamWorkspaceShareSubmissionModalInstanceController from './team_workspace/controllers/team-workspace-share-submission-modal-instance-controller';
import TeamsResources from './teams/services/teams-resources';
import TextLectureComponentModel from './lecture_pages/services/components/text-lecture-component-model';
import TimedQuizCtrl from './quizzes/controllers/timed-quiz-controller';
import TimedQuizEditFormModalCtrl from './lecture_pages/controllers/timed-quiz-edit-form-modal-controller';
import TimedQuizFeedbackCtrl from './quizzes/controllers/timed-quiz-feedback-controller';
import TimedQuizLectureComponentModel from './lecture_pages/services/components/timed-quiz-lecture-component-model';
import TimedQuizzesManager from './quizzes/services/timed-quizzes-manager';
import TimelineBaseCtrl from './timelines/controllers/timeline-base-controller';
import TimelineDiscussionLectureComponentModel from './timelines/services/individual_components/timeline-discussion-lecture-component-model';
import TimelineExerciseLectureComponent from './timelines/services/individual_components/timeline-exercise-lecture-component-model';
import TimelineExerciseSkillsRatingLectureComponent from './timelines/services/individual_components/timeline-exercise-skills-rating-lecture-component-model';
import TimelineExternalToolLectureComponentModel from './timelines/services/individual_components/timeline-external-lecture-component-model';
import TimelineGroupFormationLectureComponentModel from './timelines/services/individual_components/timeline-group-formation-lecture-component-model';
import TimelineLecturePageModel from './timelines/services/timeline-lecture-page-model';
import TimelineLectureSectionModel from './timelines/services/timeline-lecture-section-model';
import TimelineLectureSubsectionModel from './timelines/services/timeline-lecture-subsection-model';
import TimelineLectureVideoModel from './timelines/services/individual_components/timeline-lecture-video-model';
import TimelineLiveSessionLectureComponentModel from './timelines/services/individual_components/timeline-live-session-lecture-component-model';
import TimelineModel from './timelines/services/timeline-model';
import TimelinePeerReviewLectureComponentModel from './timelines/services/individual_components/timeline-peer-review-lecture-component-model';
import TimelineQuizLectureComponentModel from './timelines/services/individual_components/timeline-quiz-lecture-component-model';
import TimelinePollLectureComponentModel from './timelines/services/individual_components/timeline-poll-lecture-component-model';
import TimelineShowCtrl from './timelines/controllers/timeline-show-controller';
import TimelineSurveyLectureComponentModel from './timelines/services/individual_components/timeline-survey-lecture-component-model';
import TimelineTeamFormationLectureComponentModel from './timelines/services/individual_components/timeline-team-formation-lecture-component-model';
import TimelineTimedQuizLectureComponentModel from './timelines/services/individual_components/timeline-timed-quiz-lecture-component-model';
import TimelineProgressiveQuizLectureComponentModel from './timelines/services/individual_components/timeline-progressive-quiz-lecture-component-model';
import TimelineVideoListLectureComponentModel from './timelines/services/individual_components/timeline-video-list-lecture-component-model';
import TimelineVideoPracticeLectureComponentModel from './timelines/services/individual_components/timeline-video-practice-lecture-component-model';
import TimelineProfileCompletionLectureComponentModel from './timelines/services/individual_components/timeline-profile-completion-lecture-component-model';
import TimelinePublicPracticeFeedbackCriteriaLectureComponentModel from './timelines/services/individual_components/timeline-video-practice-feedback-lecture-component-model';
import TimelinesManager from './timelines/services/timelines-manager';
import TimelinesResources from './timelines/services/timelines-resource';
import TimezonesService from './account_settings/services/timezones-service';
import TodoBaseModel from './shared/services/todo-base-model';
import TopicModel from './discussions/services/topic-model';
import TopicsResources from './discussions/services/topics-resource';
import UploadLiveSessionAttendeesModalController from './lecture_pages/controllers/upload-live-session-attendees-modal-controller';
import UploadLiveSessionRecordingModalController from './lecture_pages/controllers/upload-live-session-recording-modal-controller';
import UploadUsersCsvController from './user_management/controllers/upload-users-csv-controller';
import UserAuthentication from './users/services/user-authentication';
import UserCourseModel from './user-courses/services/user-course-model';
import UserCoursesResource from './user-courses/services/user-courses-resource';
import UserManagementHeaderController from './user_management/controllers/user-management-header-ctrl';
import UserManagementMainController from './user_management/controllers/user-management-main-ctrl';
import UserManagementResources from './user_management/services/user-management-resources';
import UserManager from './users/services/user-manager';
import UserModel from './users/services/user-model';
import UserSignInCtrl from './users/controllers/user-sign-in-ctrl';
import UserSignUpCtrl from './users/controllers/user-sign-up-ctrl';
import UsersResources from './users/services/users-resources';
import VideoDiscussionEditFormModalCtrl from './lecture_pages/controllers/video-discussion-edit-form-modal-controller';
import VideoListEditFormModalCtrl from './lecture_pages/controllers/video-list-edit-form-modal-controller';
import VideoPracticeEditFormModalCtrl from './lecture_pages/controllers/video-practice-edit-form-modal-controller.jsx';
import MeetAndGreetEditFormModalCtrl from './lecture_pages/controllers/meet-and-greet-edit-form-modal-controller.jsx';
import LiveSessionEditFormModalCtrl from './lecture_pages/controllers/live-session-edit-form-modal-controller.jsx';
import VideoListLectureComponentModel from './lecture_pages/services/components/video-list-lecture-component-model';
import VideoPracticeLectureComponentModel from './lecture_pages/services/components/video-practice-lecture-component-model';
import PublicPracticeFeedbackCriteriaLectureComponentModel from './lecture_pages/services/components/video-practice-evaluation-lecture-component-model';
import MeetAndGreetLectureComponentModel from './lecture_pages/services/components/meet-and-greet-lecture-component-model';
import VideoPracticeEvaluationCtrl from './lecture_pages/controllers/video-practice-evaluation-controller.jsx';
import VideosManager from './shared/services/videos-manager';
import WorkspaceMeetingModalInstanceController from './team_workspace/controllers/workspace-meeting-modal-instance-controller';
import a from './shared/directives/a';
import TooltipPlacement from './shared/directives/tooltip-placement';
import PopoverPlacement from './shared/directives/popover-placement';
import createFromPresetCtrl from './timelines/controllers/create-from-preset-controller';
import MentoringProgramsCtrl from './athena/controllers/mentoring-programs-controller';
import MentoringProgramCtrl from './athena/controllers/mentoring-program-controller';
import form from './shared/directives/form';
import maxHeight from './shared/directives/max-height';
import nvActiveDraftCard from './dashboard/directives/nv-active-draft-card';
import nvAlertMessage from './shared/directives/nv-alert-message';
import nvAudioList from './lecture_videos/directives/nv-audio-list';
import nvBindCss from './shared/directives/nv-bind-css';
import nvBindHtmlUnsafe from './shared/directives/nv-bind-html-unsafe';
import nvBoxPreview from './shared/directives/nv-box-preview';
import EmbeddingsResource from './shared/services/embeddings-resources';
import nvClickToEdit from './learner_profiles/directives/nv-click-to-edit';
import nvClickToEditText from './shared/directives/nv-click-to-edit-text';
import nvColorPicker from './shared/directives/nv-color-picker';
import nvCompileAlways from './shared/directives/nv-compile-always';
import nvCompileOnce from './shared/directives/nv-compile-once';
import nvCompiledAttributes from './shared/directives/nv-compiled-attributes';
import nvCompletedCourseCard from './dashboard/directives/nv-completed-course-card';
import nvCountdownTimer from './shared/directives/nv-countdown-timer';
import nvCourseAccessBadge from './dashboard/directives/nv-course-access-badge';
import nvCourseThumbnail from './dashboard/directives/nv-course-thumbnail';
import nvCourseTitleLink from './shared/directives/nv-course-title-link';
import nvCriteriaProgressBar from './course_home/directives/nv-criteria-progress-bar';
import nvProgressGauge from './shared/directives/nv-progress-gauge';
import nvCurrentPage from './shared/services/nv-current-page';
import nvCustomValidationPopover from './shared/directives/nv-custom-validation-popover';
import nvDataDownloadsCard from './custom_downloads/directives/nv-data-downloads-card';
import nvDateIsoFix from './style-guide/directives/date-iso-fix';
import nvDateRange from './shared/filters/nv-date-range';
import nvDatepicker from './shared/directives/nv-datepicker';
import nvDirectoryProfileFiltersList from './directories/directives/nv-directory-profile-filters-list';
import nvDiscussionAvatar from './discussions/directives/nv-discussion-avatar';
import nvDiscussionComment from './discussions/directives/nv-discussion-comment';
import nvDiscussionLikes from './discussions/directives/nv-discussion-likes';
import nvDiscussionNewComment from './discussions/directives/nv-discussion-new-comment';
import nvDiscussionNewReply from './discussions/directives/nv-discussion-new-reply';
import nvDiscussionPost from './discussions/directives/nv-discussion-post';
import nvDiscussionReply from './discussions/directives/nv-discussion-reply';
import nvDiscussionTopicEditOptions from './discussions/directives/nv-discussion-topic-edit-options';
import nvDndScroll from './timelines/directives/nv-dnd-scroll';
import nvDynamicDropdown from './shared/directives/nv-dynamic-dropdown';
import nvExerciseStatus from './exercises/directives/nv-exercise-status';
import nvExerciseStudentTeamFormation from './exercises/directives/nv-exercise-student-team-formation';
import nvExpandableSearchBar from './shared/directives/nv-expandable-search-bar';
import nvFilePreview from './shared/directives/nv-file-preview';
import nvFileUpload from './shared/directives/nv-file-upload.jsx';
import nvFileUploadProgress from './shared/directives/nv-file-upload-progress';
import nvFroalaEditor from './froala/directives/nv-froala-editor';
import nvFroalaOrigami from './froala/directives/nv-froala-origami';
import nvGoogleLocationAutocomplete from './learner_profiles/directives/nv-google-location-autocomplete';
import nvHeaderPageLinks from './shared/directives/nv-header-page-links';
import iframe from './shared/directives/iframe';
import nvIconUnreadBadge from './shared/directives/nv-icon-unread-badge';
import nvIndividualSubmission from './submissions/directives/nv-individual-submission';
import nvInitiateCallback from './shared/directives/nv-initiate-callback';
import nvInputColorPicker from './shared/directives/nv-input-color-picker';
import nvInputWithLabel from './shared/directives/nv-input-with-label';
import nvIntervalSwitchItem from './shared/directives/nv-interval-switch-item';
import nvJwPlayer from './shared/directives/nv-jwplayer';
import nvError from './shared/directives/nv-error';
import nvL1CourseHeader from './shared/directives/nv-l1-course-header';
import nvL3HeaderTeam from './exercises/directives/nv-l3-header-team';
import nvLeaderboardTableRows from './leaderboard/directives/nv-leaderboard-table-rows';
import nvLectureComponent from './lecture_pages/directives/nv-lecture-component';
import nvLectureComponentDeadline from './shared/directives/nv-lecture-component-deadline';
import nvLhs from './layouts/directives/nv-lhs';
import nvManualEllipsis from './shared/filters/nv-manual-ellipsis';
import nvMax from './shared/directives/nv-max';
import nvMin from './shared/directives/nv-min';
import nvMaxLength from './shared/directives/nv-max-length';
import nvAriaAnchor from './shared/directives/nv-aria-anchor';
import nvModalPagePagination from './layouts/directives/nv-modal-page-pagination';
import nvMultiselectDropdown from './custom_downloads/directives/nv-multiselect-dropdown';
import nvNewObjectsAbove from './shared/directives/nv-new-objects-above';
import nvNewTimelineItem from './timelines/directives/nv-new-timeline-item';
import nvTimelineItemCardView from './timelines/directives/nv-timeline-item-card-view';
import nvNotificationsPopover from './shared/directives/nv-notifications-popover';
import nvNumberConversion from './shared/directives/nv-number-conversion';
import nvNumberToAlphabet from './shared/filters/nv-number-to-alphabet';
import nvOnScroll from './shared/directives/nv-on-scroll';
import nvPixelOnly from './shared/directives/nv-pixel-only';
import nvPointsDecayPopover from './shared/directives/nv-points-decay-popover';
import nvPrivacyLink from './shared/directives/nv-privacy-link';
import nvProfilePicture from './shared/directives/nv-profile-picture';
import nvProgressIcon from './shared/directives/nv-progress-icon';
import nvQuestionTemplate from './quizzes/directives/nv-question-template';
import nvQuizCorrectAnswer from './quizzes/directives/nv-quiz-correct-answer';
import nvQuizPoints from './quizzes/directives/nv-quiz-points';
import nvQuizQuestion from './quizzes/directives/nv-quiz-question';
import nvRatioMinHeight from './lecture_videos/directives/nv-ratio-min-height';
import nvRecentActivity from './shared/directives/nv-recent-activity';
import nvReportForm from './reports/directives/nv-report-form';
import nvReportSection from './reports/directives/nv-report-section';
import nvRhs from './layouts/directives/nv-rhs';
import nvRightClick from './shared/directives/nv-right-click';
import nvScrollTo from './shared/directives/nv-scroll-to';
import nvScrollToInModal from './shared/directives/nv-scroll-to-in-modal';
import nvSearchInput from './shared/directives/nv-search-input';
import nvProgramBadge from './shared/directives/nv-program-badge';
import nvSecondsToClockTime from './shared/filters/nv-seconds-to-clock-time';
import nvSelectDropdown from './custom_downloads/directives/nv-select-dropdown';
import nvSetFocus from './shared/directives/nv-set-focus';
import nvSetVisibility from './shared/directives/nv-set-visibility';
import nvShowAllToggle from './dashboard/directives/nv-show-all-toggle';
import nvShowOnFocus from './shared/directives/nv-show-on-focus';
import nvSimpleProfilePicture from './shared/directives/nv-simple-profile-picture';
import nvStatementAndCertificateCard from './dashboard/directives/nv-statement-and-certificate-card';
import nvSubmissionEditOptions from './submissions/directives/nv-submission-edit-options';
import nvSubmissionGalleryCard from './submissions/directives/nv-submission-gallery-card';
import nvSubmissionsCarousel from './shared/directives/nv-submissions-carousel';
import nvSubmitButtonValidationPopover from './shared/directives/nv-submit-button-validation-popover';
import nvTeamCard from './learner_profiles/directives/nv-team-card';
import nvTeamHeader from './exercises/directives/nv-team-header';
import nvTextFieldPrefix from './shared/directives/nv-text-field-prefix';
import nvTimeEstimates from './shared/directives/nv-time-estimates';
import nvTimedExamLimit from './lecture_pages/directives/components/nv-timed-exam-limit';
import nvTimelineItem from './timelines/directives/nv-timeline-item';
import nvTimelineLecturePageItem from './timelines/directives/nv-timeline-lecture-page-item';
import nvTimelineSectionHeader from './timelines/directives/nv-timeline-section-header';
import nvTimelineSubsectionHeader from './timelines/directives/nv-timeline-subsection-header';
import nvTimelineWrapperLecturePage from './timelines/directives/nv-timeline-wrapper-lecture-page';
import nvTodoRow from './dashboard/directives/nv-todo-row';
import nvToggleFocus from './shared/directives/nv-toggle-focus';
import nvUniqueRoleName from './institutions/directives/nv-unique-role-name';
import nvUniqueEntry from './institutions/directives/nv-unique-entry';
import nvUnreadBadge from './shared/directives/nv-unread-badge';
import nvCsvUploadUsers from './shared/directives/nv-csv-upload-users';
import nvUrlField from './shared/directives/nv-url-field';
import nvUtil from './shared/services/nv-util';
import nvVideoDownload from './lecture_videos/directives/nv-video-download';
import nvVideoList from './lecture_videos/directives/nv-video-list';
import oeContentMath from './shared/directives/oe-content-math';
import oeMention from './shared/directives/oe-mention';
import nvVideoPreviewUnavailable from './shared/directives/nv-video-preview-unavailable';
import orderEmptyLast from './shared/filters/order-empty-last';
import {
  AccountSettingsResource, EmailUnsubscriptionsResource, EmailVerificationResource,
} from './account_settings/services/account-settings-resources';
import {
  LecturePagesResources, LecturePageComponentsResources, AttachmentsResources, LiveSessionResources,
} from './lecture_pages/services/lecture-pages-resources';
import { nvLectureVideoMainBody, LectureVideoModalInstanceCtrl } from './lecture_videos/directives/nv-lecture-video-main-body';
import nvUploadedFile from './shared/directives/nv-uploaded-file';
import {
  nvValidationPopover, nvValidationPopoverBody, validateOnBlur, nvValidateOtherOnBlur,
} from './shared/directives/nv-validation-popover';
import reactLhsAccordionComponent from './lecture_pages/directives/react-lhs-accordion-component';
import nvAddSectionSubsectionComponent from './timelines/directives/nv-add-section-subsection-component';
import nvAddSubsectionComponent from './timelines/directives/nv-add-subsection-component';
import nvCopyToOfferingDropdown from './timelines/directives/nv-copy-to-offering-dropdown';
import nvAnnouncement from './announcements/directives/nv-announcement';
import nvAttachmentLectureComponent from './lecture_pages/directives/components/nv-attachment-lecture-component.jsx';
import nvAudioListLectureComponent from './lecture_pages/directives/components/nv-audio-list-lecture-component';
import nvCheckboxDropdown from './shared/directives/nv-checkbox-dropdown';
import nvCommunityCompletionRow from './shared/directives/nv-community-completion-row';
import nvContentTemplate from './lecture_pages/directives/nv-content-template';
import nvDashboardFilterTab from './shared/directives/nv-dashboard-filter-tab';
import nvDashboardFilterTabList from './shared/directives/nv-dashboard-filter-tab-list';
import nvDiscussionLectureComponent from './lecture_pages/directives/components/nv-discussion-lecture-component';
import nvExerciseLectureComponent from './lecture_pages/directives/components/nv-exercise-lecture-component.jsx';
import nvExternalToolLectureComponent from './lecture_pages/directives/components/nv-external-tool-lecture-component.jsx';
import nvLearnerExerciseReportDeliverable from './exercises/directives/nv-learner-exercise-report-deliverable';
import nvLiveSessionLectureComponent from './lecture_pages/directives/components/nv-live-session-lecture-component.jsx';
import nvMenteeProgressBar from './mentors/directives/nv-mentee-progress-bar';
import nvMenteesButton from './shared/directives/nv-mentees-button';
import nvPeerEvaluationLectureComponent from './lecture_pages/directives/components/nv-peer-evaluation-lecture-component';
import nvPostBody from './shared/directives/nv-post-body';
import nvPostContainer from './shared/directives/nv-post-container';
import nvQuizLectureComponent from './lecture_pages/directives/components/nv-quiz-lecture-component';
import nvResponsiveTabs from './shared/directives/nv-responsive-tabs';
import nvRolesDropdown from './user_management/directives/nv-roles-dropdown';
import nvRolesRadio from './user_management/directives/nv-roles-radio';
import nvSubmissionStatusText from './exercises/directives/nv-submission-status-text';
import nvAnimatedNextButton from './lecture_pages/directives/components/nv-animated-next-button';
import nvSubmissionsDiscoveryLectureComponent from './lecture_pages/directives/components/nv-submission-discovery-lecture-component';
import nvSurveyLectureComponent from './lecture_pages/directives/components/nv-survey-lecture-component';
import nvTeamFormationLectureComponent from './lecture_pages/directives/components/nv-team-formation-lecture-component';
import nvTeamWorkspaceAvatar from './team_workspace/directives/nv-team-workspace-avatar';
import nvTeamWorkspaceEditPost from './team_workspace/directives/nv-team-workspace-edit-post';
import nvTeamWorkspaceNewPost from './team_workspace/directives/nv-team-workspace-new-post';
import nvTeamWorkspacePost from './team_workspace/directives/nv-team-workspace-post';
import nvTeamProfilePanel from './team_workspace/directives/nv-team-profile-panel';
import nvTimedQuizLectureComponent from './lecture_pages/directives/components/nv-timed-quiz-lecture-component';
import nvVideoListLectureComponent from './lecture_pages/directives/components/nv-video-list-lecture-component';
import { getModalManager } from './shared/components/nv-modal';
import nvSubmissionApprovalComments from './submissions/directives/nv-submission-approval-comments';
import nvLiDisabled from './shared/directives/nv-li-disabled';
import nvManualAddUser from './shared/directives/nv-manual-add-user';
import nvSubmissionApprovalNewComment from './submission_approval_dashboard/directives/nv-submission-approval-new-comment';
import nvDropdownItem from './shared/directives/nv-dropdown-item';
import nvReactComponent from './shared/directives/nv-react-component';
import NvFirefoxAutomaticScrollToFocusedElement from './shared/directives/nv-firefox-automatic-scroll-to-focused-element';
import PrimaryBadge from './cohort_management/directives/primary-badge';
import SubmissionApprovalDashboardCtrl from './submission_approval_dashboard/controllers/submission-approval-dashboard-controller';
import SubmissionApprovalBaseCtrl from './submission_approval_dashboard/controllers/submission-approval-sort-filter-controller';
import ManageMenteesModalController from './user_management/controllers/manage-mentees-modal-controller';
import DataExportsModalController from './institutions/controllers/data-exports-modal-controller';
import MentoringsResource from './user_management/services/mentorings-resource';
import nvRolesBadge from './shared/directives/nv-role-badge';
import SubmissionApprovalResource from './submission_approval_dashboard/services/submission-approval-dashboard-resources';
import OfflineInterceptor from './shared/services/offline-interceptor';
import InstitutionDashboardReactCtrl from './institutions/controllers/institution-dashboard-react-ctrl';
import CourseCommunicationsReactCtrl from './communications/course_communications/controllers/course-communications-react-ctrl';
// import JourneyCommunicationsReactCtrl from './communications/journey_communications/controllers/journey-communications-react-ctrl';
import Bs4TestbedCtrl from './style-guide/controllers/bs4-testbed-ctrl';
import ScratchpadCtrl from './style-guide/controllers/scratchpad-ctrl';
import nvSearchPanel from './shared/directives/nv-search-panel';
import nvCompletionCriteria from './timelines/directives/nv-completion-criteria';
import nvCompletionCriteriaHeader from './timelines/directives/nv-completion-criteria-header';
import OrgUserDetailsCtrl from './org_mentor/controllers/learner-enrollment-modal-controller';
import AwardExtraPointsCtrl from './learner_progress/controllers/award-extra-points-controller';
import ModifyContentAccessExtensionController from './user_management/controllers/modify-content-access-extension-controller';
import nvTimelineRequiredAssignmentsForCompletion from './timelines/directives/nv-timeline-required-assignments-for-completion';
import nvTimelineRequiredTodosForCompletion from './timelines/directives/nv-timeline-required-todos-for-completion';
import nvNewTimelineTodos from './timelines/directives/nv-new-timeline-todos';
import nvNewTimelineFull from './timelines/directives/nv-new-timeline-full.jsx';
import nvProgressBarGroup from './mentors/directives/nv-progress-bar-group';
import nvNewTimelinePointsList from './timelines/directives/nv-new-timeline-points-list';

import LightHeaderController from './shared/controllers/light-header-ctrl';
import TeamFacilitationController from './team_facilitation/directives/team-facilitation-react-ctrl';
import ProfileCompletionEditFormModalCtrl from './lecture_pages/controllers/profile-completion-edit-form-modal-controller';
import OrgLevelProfileCtrl from './org_level_profile/controllers/org-level-profile-controller';
import OrgLevelProfileContentHeaderCtrl from './org_level_profile/controllers/org-level-profile-content-header-controller';
import ProgressiveQuizModalController from './quizzes/controllers/progressive-quiz-modal-controller';
import L4ModalController from './layouts/controllers/l4-modal-controller';
import OrgLevelProfileManager from './org_level_profile/services/org-level-profile-manager';
import MigratedFields from './org_level_profile/services/migrated-fields';
import LearningJourneyService from './learning_journeys/services/learning-journey-service';
import UserEnrollmentDetailsPageHeaderCtrl from './institutions/controllers/user-enrollment-details-page-header-controller';

import nvTranslationButton from './shared/directives/nv-translation-button'

// Some of these, particularly the modules from angular_translate_init, need to run after we've humps-ified the config files above
import constants from './shared/services/constants';
import s3NameSpaces from './shared/services/s3-upload-factory';

import {
  $translateStaticFilesLoader, configureTranslateProvider,
} from './angular_translate_init';

import routes from './routes';
import athenaRoutes from './athena/routes';
import routesDev from './routes-dev';
import NonDev from './non-dev';

import { configAxios } from './shared/axios/axios-config';
import { insertGoogleAnalytics, updateGoogleAnalyticsId, resetGoogleAnalytics, gtag } from './shared/utils';

// Webpack will auto-resolve jQuery (`$`) via ProvidePlugin, however we want to
// make sure that JQuery is also accessible through the global `window.$`
window.$ = $;
window.jQuery = $;
// this line also ensures that Froala is initialized before any usage by triggering Webpack auto-resolve
window.FroalaEditor = FroalaEditor;

window._ = _;
window.humps = humps;
window.BigNumber = bignumber;
// window.viewportUnitsBuggyfill = viewportUnitsBuggyfill;

// Imports our app specific styles
// eslint-disable-next-line import/first
import './styles/react-app';

if (process.env.NODE_ENV !== 'production') {
  // Using import on these triggers this https://stackoverflow.com/questions/37902849/import-and-export-may-only-appear-at-the-top-level
  // even when using the linked babel plugin
  require('./styles/vendor.scss');
  require('./styles/application.scss');
} else {
  /* eslint-disable import/no-unresolved */
  require('../.tmp/styles/vendor.scss');
  require('../.tmp/styles/application.scss');
  /* eslint-enable import/no-unresolved */
}

const novoEdMomentMapping = {
  'MOMENT.MONTH_DAY_YEAR': 'l',
  'MOMENT.MONTH_DAY_YY': 'l',
  'MOMENT.MONTH_DAY': 'l',
  'MOMENT.MMM_DAY': 'll',
  'MOMENT.TIME': 'LT',
  'MOMENT.MONTH_DAY_TIME': 'lll',
  'MOMENT.MONTH_SLASH_DAY_AT_TIME': 'lll',
  'MOMENT.MONTH_DAY_AT_TIME': 'lll',
  'MOMENT.MONTH_DAY_AT_NOSPACE_TIME': 'lll',
  'MOMENT.MONTH_DAYTH_AT_TIME': 'lll',
  'MOMENT.MONTH_DAY_COMMA_YEAR_TIME': 'lll',
  'MOMENT.MONTH_DAY_COMMA_YEAR_AT_TIME': 'lll',
  'MOMENT.MONTH_DAY_YEAR_COMMA_TIME': 'lll',
  'MOMENT.MONTH_DAY_YEAR_TIME': 'lll',
  'MOMENT.MONTH_DAY_YEAR_TIME_IN_FULL': 'LLL',
  'MOMENT.NAMED_DAY_MONTH_DAY': 'LLLL',
};

angular.module('pascalprecht.translate')
  .factory('$translateStaticFilesLoader', $translateStaticFilesLoader);

angular.module('nv.config', [])
  .constant('config', config);

const angularModules = [
  // Native
  'ngAnimate',
  'ngCookies',
  'ngResource',
  'ngRoute',
  'ngSanitize',
  'ngTouch',
  'ui.sortable',
  'ngAria',

  // Libraries
  'ui.router',

  // injecting only what we need doesn't prevent it from being excluded in js, but still good practice
  // 'ui.bootstrap',
  'ui.bootstrap.tpls',
  'ui.bootstrap.collapse',
  'ui.bootstrap.accordion',
  // 'ui.bootstrap.alert',
  'ui.bootstrap.buttons',
  // 'ui.bootstrap.carousel',
  // 'ui.bootstrap.dateparser',
  // 'ui.bootstrap.isClass',
  // 'ui.bootstrap.datepicker',
  'ui.bootstrap.position',
  // 'ui.bootstrap.datepickerPopup',
  // 'ui.bootstrap.debounce',
  'ui.bootstrap.dropdown',
  'ui.bootstrap.stackedMap',
  'ui.bootstrap.modal',
  // 'ui.bootstrap.paging',
  // 'ui.bootstrap.pager',
  // 'ui.bootstrap.pagination',
  'ui.bootstrap.tooltip',
  'ui.bootstrap.popover',
  'ui.bootstrap.progressbar',
  // 'ui.bootstrap.rating',
  'ui.bootstrap.tabs',
  // 'ui.bootstrap.timepicker',
  'ui.bootstrap.typeahead',


  'ui.indeterminate',
  'ui.scroll',
  // the following modules come from 'ct.ui.router.extras' -  we are intentionally excluding 'ct.ui.router.extras.future'
  // we aren't using future, but also https://github.com/christopherthielen/ui-router-extras/issues/220
  'ct.ui.router.extras.core',
  'ct.ui.router.extras.sticky',
  'ct.ui.router.extras.dsr',
  'ct.ui.router.extras.previous',
  'ct.ui.router.extras.statevis',
  'ct.ui.router.extras.transition',
  'pascalprecht.translate',
  'pusher-angular',
  'angularMoment',
  'ngFileUpload',
  'angular-inview',
  'duScroll',
  'validation.match',
  'rzSlider',
  'mentio',
  'slickCarousel',
  'vcRecaptcha',
  'angular-clipboard',
  '720kb.socialshare',
  'dndLists',

  // NovoEd
  'nv.config',
];

if (sentryEnabled) {
  initSentry(angular);
  angularModules.push('ngSentry');
}

const app = angular.module('origamiApp', angularModules);

if (process.env.NODE_ENV === 'production') {
  app.config(NonDev);
} else {
  app.config(routesDev);
}

app
  .controller('AccountBasicsCtrl', AccountBasicsCtrl)
  .controller('AccountSettingsNavCtrl', AccountSettingsNavCtrl)
  .controller('AdminAddMembersModalCtrl', AdminAddMembersModalCtrl)
  .controller('AdminControlsCtrl', AdminControlsCtrl)
  .controller('AnnouncementFormModalCtrl', AnnouncementFormModalCtrl)
  .controller('AnnouncementViewEmailModalCtrl', AnnouncementViewEmailModalCtrl)
  .controller('AnnouncementsCourseListCtrl', AnnouncementsCourseListCtrl)
  .controller('AnnouncementsCtrl', AnnouncementsCtrl)
  .controller('AnnouncementsForCoursePreviewCtrl', AnnouncementsForCoursePreviewCtrl)
  .controller('AnnouncementsModalCtrl', AnnouncementsModalCtrl)
  .controller('AnnouncementsPreviewCtrl', AnnouncementsPreviewCtrl)
  .controller('AssignmentIntroModalController', AssignmentIntroModalController)
  .controller('AttachModalResolvesToVmCtrl', AttachModalResolvesToVmCtrl)
  .controller('AuthenticationsCtrl', AuthenticationsCtrl)
  .controller('BulkEditDeadlineCtrl', BulkEditDeadlineCtrl)
  .controller('BulkEditReleaseDatesCtrl', BulkEditReleaseDatesCtrl)
  .controller('CopyToOfferingModalCtrl', CopyToOfferingModalCtrl)
  .controller('ChangeInstitutionCtrl', ChangeInstitutionCtrl)
  .controller('ConfigureSSOModalCtrl', ConfigureSSOModalCtrl)
  .controller('ConfigureTOSModalCtrl', ConfigureTOSModalCtrl)
  .controller('ConfigureZoomModalCtrl', ConfigureZoomModalCtrl)
  .controller('ConfigureDegreedModalCtrl', ConfigureDegreedModalCtrl)
  .controller('ConfigureMsTeamsBotIntegrationModalCtrl', ConfigureMsTeamsBotIntegrationModalCtrl)
  .controller('ConfigureLmsModalCtrl', ConfigureLmsModalCtrl)
  .controller('ContentTemplateEditFormModalCtrl', ContentTemplateEditFormModalCtrl)
  .controller('ConversationsPreviewCtrl', ConversationsPreviewCtrl)
  .controller('CourseFormBaseCtrl', CourseFormBaseCtrl)
  .controller('CourseHomeBaseCtrl', CourseHomeBaseCtrl)
  .controller('CourseHomeCtrl', CourseHomeCtrl)
  .controller('CourseHomeRhsBaseCtrl', CourseHomeRhsBaseCtrl)
  .controller('CourseHomeRhsCtrl', CourseHomeRhsCtrl)
  .controller('CreateContentDownloadCtrl', CreateContentDownloadCtrl)
  .controller('CreateReportsCtrl', CreateReportsCtrl)
  .controller('CustomDownloadsCtrl', CustomDownloadsCtrl)
  .controller('DashboardCtrl', DashboardCtrl)
  .controller('MentorDashboard', MentorDashboard)
  .controller('CourseAdminDashboardCtrl', CourseAdminDashboardCtrl)
  .controller('LearningCatalogCtrl', LearningCatalogCtrl)
  .controller('DashboardHeaderController', DashboardHeaderController)
  .controller('DataDownloadsListCtrl', DataDownloadsListCtrl)
  .controller('DirectLinkCtrl', DirectLinkCtrl)
  .controller('DiscussionFormModalCtrl', DiscussionFormModalCtrl)
  .controller('DiscussionSortFilterCtrl', DiscussionSortFilterCtrl)
  .controller('DiscussionsIndexCtrl', DiscussionsIndexCtrl)
  .controller('DiscussionsRhsCtrl', DiscussionsRhsCtrl)
  .controller('DocumentWorkspacePostModalInstanceController', DocumentWorkspacePostModalInstanceController)
  .controller('DownloadDetailsCtrl', DownloadDetailsCtrl)
  .controller('EditCourseCtrl', EditCourseCtrl)
  .controller('EmailPreferencesCtrl', EmailPreferencesCtrl)
  .controller('EvaluationsFormBaseCtrl', EvaluationsFormBaseCtrl)
  .controller('EvaluationsFormCtrl', EvaluationsFormCtrl)
  .controller('ExerciseEditFormModalCtrl', ExerciseEditFormModalCtrl)
  .controller('ExerciseShowBaseCtrl', ExerciseShowBaseCtrl)
  .controller('ExerciseShareSubmissionModalCtrl', ExerciseShareSubmissionModalCtrl)
  .controller('ExerciseShareWithTeamModalCtrl', ExerciseShareWithTeamModalCtrl)
  .controller('FeedbackEditFormModalCtrl', FeedbackEditFormModalCtrl)
  .controller('FlyerCtrl', FlyerCtrl)
  .controller('FramedFroalaCtrl', FramedFroalaCtrl)
  .controller('FroalaFilePopoverCtrl', FroalaFilePopoverCtrl)
  .controller('GroupDirectoryMainController', GroupDirectoryMainController)
  .controller('GroupDirectorySortFilterController', GroupDirectorySortFilterController)
  .controller('HighlightPostModalInstanceCtrl', HighlightPostModalInstanceCtrl)
  .controller('IndividualSubmissionBaseCtrl', IndividualSubmissionBaseCtrl)
  .controller('IndividualSubmissionCtrl', IndividualSubmissionCtrl)
  .controller('IndividualSubmissionPaginationCtrl', IndividualSubmissionPaginationCtrl)
  .controller('IndividualVideoEditCtrl', IndividualVideoEditCtrl)
  .controller('InformalFeedbackModalCtrl', InformalFeedbackModalCtrl)
  .controller('InstitutionAdvancedSettingsCtrl', InstitutionAdvancedSettingsCtrl)
  .controller('InstitutionEditEmailTemplateCtrl', InstitutionEditEmailTemplateCtrl)
  .controller('InstitutionEmailTemplateCtrl', InstitutionEmailTemplateCtrl)
  .controller('LearningJourneysCtrl', LearningJourneysCtrl)
  .controller('LearningJourneyCtrl', LearningJourneyCtrl)
  .controller('InstitutionRolesCtrl', InstitutionRolesCtrl)
  .controller('InvitationsAndRequestsCtrl', InvitationsAndRequestsCtrl)
  .controller('L3EditReportHeaderCtrl', L3EditReportHeaderCtrl)
  .controller('L3HeaderCtrl', L3HeaderCtrl)
  .controller('OrgL3HeaderCtrl', OrgL3HeaderCtrl)
  .controller('L3ShowExerciseHeaderCtrl', L3ShowExerciseHeaderCtrl)
  .controller('L3ShowReportHeaderCtrl', L3ShowReportHeaderCtrl)
  .controller('LangsAndTZController', LangsAndTZController)
  .controller('LanguageModalCtrl', LanguageModalCtrl)
  .controller('LeaderboardCtrl', LeaderboardCtrl)
  .controller('LearnerDirectoryAllFiltersModalCtrl', LearnerDirectoryAllFiltersModalCtrl)
  .controller('LearnerDirectoryMainController', LearnerDirectoryMainController)
  .controller('LearnerDirectorySortFilterController', LearnerDirectorySortFilterController)
  .controller('LearnerProfileBaseCtrl', LearnerProfileBaseCtrl)
  .controller('LearnerProfileCtrl', LearnerProfileCtrl)
  .controller('LearnerProfilePaginationCtrl', LearnerProfilePaginationCtrl)
  .controller('LearnerProgressDashboardCtrl', LearnerProgressDashboardCtrl)
  .controller('LeavePracticeActivityCtrl', LeavePracticeActivityCtrl)
  .controller('LectureComponentBaseCtrl', LectureComponentBaseCtrl)
  .controller('LectureComponentModalCtrl', LectureComponentModalCtrl)
  .controller('LectureLightHeaderCtrl', LectureLightHeaderCtrl)
  .controller('LecturePageReactCtrl', LecturePageReactCtrl)
  .controller('LectureVideoModalInstanceCtrl', LectureVideoModalInstanceCtrl)
  .controller('LectureVideosBaseCtrl', LectureVideosBaseCtrl)
  .controller('LectureVideosCtrl', LectureVideosCtrl)
  .controller('LeftNavUserCtrl', LeftNavUserCtrl)
  .controller('LhsCoursesCtrl', LhsCoursesCtrl)
  .controller('LhsPracticeRoomCtrl', LhsPracticeRoomCtrl)
  .controller('MainContentCtrl', MainContentCtrl)
  .controller('MainGridCtrl', MainGridCtrl)
  .controller('MainGridHamburgerMenuCtrl', MainGridHamburgerMenuCtrl)
  .controller('ManageCourseRolesCtrl', ManageCourseRolesCtrl)
  .controller('ManageTeamGroupReactCtrl', ManageTeamGroupReactCtrl)
  .controller('InstitutionDashboardReactCtrl', InstitutionDashboardReactCtrl)
  .controller('CourseCommunicationsReactCtrl', CourseCommunicationsReactCtrl)
  // .controller('JourneyCommunicationsReactCtrl', JourneyCommunicationsReactCtrl)
  .controller('Bs4TestbedCtrl', Bs4TestbedCtrl)
  .controller('ScratchpadCtrl', ScratchpadCtrl)
  .controller('OrgUserDetailsCtrl', OrgUserDetailsCtrl)
  .controller('ManualAddUserModalController', ManualAddUserModalController)
  .controller('MediaUploadModalInstanceCtrl', MediaUploadModalInstanceCtrl)
  .controller('MeetingRsvpModalInstanceController', MeetingRsvpModalInstanceController)
  .controller('MenteesPageBaseController', MenteesPageBaseController)
  .controller('MenteesPageController', MenteesPageController)
  .controller('MenteesPageHeaderController', MenteesPageHeaderController)
  .controller('NewCourseCtrl', NewCourseCtrl)
  .controller('NewPostModalCtrl', NewPostModalCtrl);
// We have to break this declaration chain into several smaller chains to avoid an ittermittent
// "Maximum call stack size exceeded" error in babel-loader
app.controller('NewTopicModalCtrl', NewTopicModalCtrl)
  .controller('NotificationsCtrl', NotificationsCtrl)
  .controller('NvModalCtrl', NvModalCtrl)
  .controller('OrgMentorCtrl', OrgMentorCtrl)
  .controller('PeerReviewsBaseCtrl', PeerReviewsBaseCtrl)
  .controller('PointsBreakdownModalController', PointsBreakdownModalController)
  .controller('PointsModalCtrl', PointsModalCtrl)
  .controller('PracticeRoomFlyoutReactCtrl', PracticeRoomFlyoutReactCtrl)
  .controller('PracticeRoomReactCtrl', PracticeRoomReactCtrl)
  .controller('PrivacyPolicyModalCtrl', PrivacyPolicyModalCtrl)
  .controller('ProgramHomeController', ProgramHomeController)
  .controller('QuizAnswerFeedbackCtrl', QuizAnswerFeedbackCtrl)
  .controller('QuizFormBaseCtrl', QuizFormBaseCtrl)
  .controller('QuizFormCtrl', QuizFormCtrl)
  .controller('ReportFormHeaderCtrl', ReportFormHeaderCtrl)
  .controller('ReportSubmittedCtrl', ReportSubmittedCtrl)
  .controller('RequestPasswordResetCtrl', RequestPasswordResetCtrl)
  .controller('ResetPasswordCtrl', ResetPasswordCtrl)
  .controller('SendMessageModalCtrl', SendMessageModalCtrl)
  .controller('StatementBaseCtrl', StatementBaseCtrl)
  .controller('StatementCtrl', StatementCtrl)
  .controller('StickyHeaderFooterCtrl', StickyHeaderFooterCtrl)
  .controller('FlyOutPanelController', FlyOutPanelController)
  .controller('ContentSearchFlyoutCtrl', ContentSearchFlyoutCtrl)
  .controller('BookmarksFlyoutCtrl', BookmarksFlyoutCtrl)
  .controller('AdminToolsFlyoutCtrl', AdminToolsFlyoutCtrl)
  .controller('CourseMenteesFlyoutCtrl', CourseMenteesFlyoutCtrl)
  .controller('MyLearningFlyoutCtrl', MyLearningFlyoutCtrl)
  .controller('TeamsGroupsFlyoutCtrl', TeamsGroupsFlyoutCtrl)
  .controller('StyleGuideCodeCtrl', StyleGuideCodeCtrl)
  .controller('StyleGuideCtrl', StyleGuideCtrl)
  .controller('StyleGuideReactCtrl', StyleGuideReactCtrl)
  .controller('StyleGuideFroalaCtrl', StyleGuideFroalaCtrl)
  .controller('SubmissionGalleryCtrl', SubmissionGalleryCtrl)
  .controller('SubmissionSortFilterCtrl', SubmissionSortFilterCtrl)
  .controller('SubmissionsDiscoveryEditFormModalCtrl', SubmissionsDiscoveryEditFormModalCtrl)
  .controller('SkillTagsCtrl', SkillTagsCtrl)
  .controller('ContentLibraryCtrl', ContentLibraryCtrl)
  .controller('AnalyticsDashboardCtrl', AnalyticsDashboardCtrl)
  .controller('TeachingTeamDirectoryMainController', TeachingTeamDirectoryMainController)
  .controller('TeachingTeamDirectorySortFilterController', TeachingTeamDirectorySortFilterController)
  .controller('TeamDirectoryMainController', TeamDirectoryMainController)
  .controller('TeamDirectorySortFilterController', TeamDirectorySortFilterController)
  .controller('TeamFormModalCtrl', TeamFormModalCtrl)
  .controller('GrantLateSubmissionPermitCtrl', GrantLateSubmissionPermitCtrl)
  .controller('TeamMemberCardCtrl', TeamMemberCardCtrl)
  .controller('TeamMembershipActionsCtrl', TeamMembershipActionsCtrl)
  .controller('TeamProfileBaseCtrl', TeamProfileBaseCtrl)
  .controller('TeamProfileCtrl', TeamProfileCtrl)
  .controller('TeamProfilePaginationCtrl', TeamProfilePaginationCtrl)
  .controller('TeamProfileTeamMembersCtrl', TeamProfileTeamMembersCtrl)
  .controller('TeamWorkspaceBaseController', TeamWorkspaceBaseController)
  .controller('TeamWorkspaceDirectLinkController', TeamWorkspaceDirectLinkController)
  .controller('TeamWorkspaceMainController', TeamWorkspaceMainController)
  .controller('TeamRecentPostsCtrl', TeamRecentPostsCtrl)
  .controller('TeamRecentAssignmentsCtrl', TeamRecentAssignmentsCtrl)
  .controller('TeamAddMembersCtrl', TeamAddMembersCtrl)
  .controller('TeamWorkspaceMembersBaseController', TeamWorkspaceMembersBaseController)
  .controller('TeamWorkspaceMembersController', TeamWorkspaceMembersController)
  .controller('TeamWorkspaceShareSubmissionModalInstanceController', TeamWorkspaceShareSubmissionModalInstanceController)
  .controller('TimedQuizCtrl', TimedQuizCtrl)
  .controller('TimedQuizEditFormModalCtrl', TimedQuizEditFormModalCtrl)
  .controller('TimedQuizFeedbackCtrl', TimedQuizFeedbackCtrl)
  .controller('TimelineBaseCtrl', TimelineBaseCtrl)
  .controller('TimelineShowCtrl', TimelineShowCtrl)
  .controller('UploadLiveSessionAttendeesModalController', UploadLiveSessionAttendeesModalController)
  .controller('UploadLiveSessionRecordingModalController', UploadLiveSessionRecordingModalController)
  .controller('UploadUsersCsvController', UploadUsersCsvController)
  .controller('UserManagementHeaderController', UserManagementHeaderController)
  .controller('UserManagementMainController', UserManagementMainController)
  .controller('UserSignInCtrl', UserSignInCtrl)
  .controller('UserSignUpCtrl', UserSignUpCtrl)
  .controller('VideoDiscussionEditFormModalCtrl', VideoDiscussionEditFormModalCtrl)
  .controller('VideoListEditFormModalCtrl', VideoListEditFormModalCtrl)
  .controller('VideoPracticeEditFormModalCtrl', VideoPracticeEditFormModalCtrl)
  .controller('VideoPracticeEvaluationCtrl', VideoPracticeEvaluationCtrl)
  .controller('MeetAndGreetEditFormModalCtrl', MeetAndGreetEditFormModalCtrl)
  .controller('LiveSessionEditFormModalCtrl', LiveSessionEditFormModalCtrl)
  .controller('WorkspaceMeetingModalInstanceController', WorkspaceMeetingModalInstanceController)
  .controller('createFromPresetCtrl', createFromPresetCtrl)
  .controller('SubmissionApprovalDashboardCtrl', SubmissionApprovalDashboardCtrl)
  .controller('SubmissionApprovalBaseCtrl', SubmissionApprovalBaseCtrl)
  .controller('ManageMenteesModalController', ManageMenteesModalController)
  .controller('DataExportsModalController', DataExportsModalController)

  .controller('AwardExtraPointsCtrl', AwardExtraPointsCtrl)
  .controller('ModifyContentAccessExtensionController', ModifyContentAccessExtensionController)

  .controller('LightHeaderController', LightHeaderController)
  .controller('TeamFacilitationController', TeamFacilitationController)
  .controller('ProfileCompletionEditFormModalCtrl', ProfileCompletionEditFormModalCtrl)
  .controller('OrgLevelProfileCtrl', OrgLevelProfileCtrl)
  .controller('UserEnrollmentDetailsPageHeaderCtrl', UserEnrollmentDetailsPageHeaderCtrl)
  .controller('OrgLevelProfileContentHeaderCtrl', OrgLevelProfileContentHeaderCtrl)
  .controller('MentoringProgramsCtrl', MentoringProgramsCtrl)
  .controller('MentoringProgramCtrl', MentoringProgramCtrl)
  .controller('ProgressiveQuizModalCtrl', ProgressiveQuizModalController)
  .controller('L4ModalCtrl', L4ModalController)
  .directive('a', a)
  .directive('tooltipPlacement', TooltipPlacement)
  .directive('popoverPlacement', PopoverPlacement)
  .directive('form', form)
  .directive('nvTeamProfilePanel', nvTeamProfilePanel)
  .directive('maxHeight', maxHeight)
  .component('reactLhsAccordionComponent', reactLhsAccordionComponent)
  .component('nvAddSectionSubsectionComponent', nvAddSectionSubsectionComponent)
  .component('nvAddSubsectionComponent', nvAddSubsectionComponent)
  .component('nvCopyToOfferingDropdown', nvCopyToOfferingDropdown)
  .component('nvAnnouncement', nvAnnouncement)
  .component('nvAttachmentLectureComponent', nvAttachmentLectureComponent)
  .component('nvAudioListLectureComponent', nvAudioListLectureComponent)
  .component('nvCheckboxDropdown', nvCheckboxDropdown)
  .component('nvCommunityCompletionRow', nvCommunityCompletionRow)
  .component('nvContentTemplate', nvContentTemplate)
  .component('nvDashboardFilterTab', nvDashboardFilterTab)
  .component('nvDashboardFilterTabList', nvDashboardFilterTabList)
  .component('nvDiscussionLectureComponent', nvDiscussionLectureComponent)
  .component('nvExerciseLectureComponent', nvExerciseLectureComponent)
  .component('nvExternalToolLectureComponent', nvExternalToolLectureComponent)
  .component('nvLearnerExerciseReportDeliverable', nvLearnerExerciseReportDeliverable)
  .component('nvLiveSessionLectureComponent', nvLiveSessionLectureComponent)
  .component('nvMenteeProgressBar', nvMenteeProgressBar)
  .component('nvProgressBarGroup', nvProgressBarGroup)
  .component('nvMenteesButton', nvMenteesButton)
  .component('nvPeerEvaluationLectureComponent', nvPeerEvaluationLectureComponent)
  .component('nvPostBody', nvPostBody)
  .component('nvPostContainer', nvPostContainer)
  .component('nvQuizLectureComponent', nvQuizLectureComponent)
  .component('nvResponsiveTabs', nvResponsiveTabs)
  .component('nvRolesDropdown', nvRolesDropdown)
  .component('nvRolesRadio', nvRolesRadio)
  .component('nvSubmissionStatusText', nvSubmissionStatusText)
  .component('nvAnimatedNextButton', nvAnimatedNextButton)
  .component('nvSubmissionsDiscoveryLectureComponent', nvSubmissionsDiscoveryLectureComponent)
  .component('nvSurveyLectureComponent', nvSurveyLectureComponent)
  .component('nvTeamFormationLectureComponent', nvTeamFormationLectureComponent)
  .component('nvTeamWorkspaceAvatar', nvTeamWorkspaceAvatar)
  .component('nvTeamWorkspaceEditPost', nvTeamWorkspaceEditPost)
  .component('nvTeamWorkspaceNewPost', nvTeamWorkspaceNewPost)
  .component('nvTeamWorkspacePost', nvTeamWorkspacePost)
  .component('nvTimedQuizLectureComponent', nvTimedQuizLectureComponent)
  .component('nvVideoListLectureComponent', nvVideoListLectureComponent)
  .component('nvSubmissionApprovalComments', nvSubmissionApprovalComments)

  .component('nvSearchPanel', nvSearchPanel)
  .component('nvCompletionCriteria', nvCompletionCriteria)
  .component('nvCompletionCriteriaHeader', nvCompletionCriteriaHeader)
  .component('nvNewTimelinePointsList', nvNewTimelinePointsList)
  .component('nvTimelineRequiredAssignmentsForCompletion', nvTimelineRequiredAssignmentsForCompletion)
  .component('nvTimelineRequiredTodosForCompletion', nvTimelineRequiredTodosForCompletion)
  .component('nvNewTimelineFull', nvNewTimelineFull)
  .component('nvNewTimelineTodos', nvNewTimelineTodos)
  .component('nvProgramBadge', nvProgramBadge)

  .component('nvCsvUploadUsers', nvCsvUploadUsers)
  .component('nvManualAddUser', nvManualAddUser)

  .directive('nvActiveDraftCard', nvActiveDraftCard)
  .directive('nvAlertMessage', nvAlertMessage)
  .directive('nvAudioList', nvAudioList)
  .directive('nvBindCss', nvBindCss)
  .directive('nvBindHtmlUnsafe', nvBindHtmlUnsafe);

app.directive('nvBoxPreview', nvBoxPreview)
  .directive('nvClickToEdit', nvClickToEdit)
  .directive('nvClickToEditText', nvClickToEditText)
  .directive('nvColorPicker', nvColorPicker)
  .directive('nvCompileAlways', nvCompileAlways)
  .directive('nvCompileOnce', nvCompileOnce)
  .directive('nvCompiledAttributes', nvCompiledAttributes)
  .directive('nvCompletedCourseCard', nvCompletedCourseCard)
  .directive('nvCountdownTimer', nvCountdownTimer)
  .directive('nvCourseAccessBadge', nvCourseAccessBadge)
  .directive('nvCourseThumbnail', nvCourseThumbnail)
  .directive('nvCourseTitleLink', nvCourseTitleLink)
  .directive('nvCriteriaProgressBar', nvCriteriaProgressBar)
  .directive('nvProgressGauge', nvProgressGauge)
  .directive('nvCustomValidationPopover', nvCustomValidationPopover)
  .directive('nvDataDownloadsCard', nvDataDownloadsCard)
  .directive('nvDateIsoFix', nvDateIsoFix)
  .directive('nvDatepicker', nvDatepicker)
  .directive('nvDirectoryProfileFiltersList', nvDirectoryProfileFiltersList)
  .directive('nvDiscussionAvatar', nvDiscussionAvatar)
  .directive('nvDiscussionComment', nvDiscussionComment)
  .directive('nvDiscussionLikes', nvDiscussionLikes)
  .directive('nvDiscussionNewComment', nvDiscussionNewComment)
  .directive('nvDiscussionNewReply', nvDiscussionNewReply)
  .directive('nvDiscussionPost', nvDiscussionPost)
  .directive('nvDiscussionReply', nvDiscussionReply)
  .directive('nvDiscussionTopicEditOptions', nvDiscussionTopicEditOptions)
  .directive('nvDndScroll', nvDndScroll)
  .directive('nvDynamicDropdown', nvDynamicDropdown)
  .directive('nvExerciseStatus', nvExerciseStatus)
  .directive('nvExerciseStudentTeamFormation', nvExerciseStudentTeamFormation)
  .directive('nvExpandableSearchBar', nvExpandableSearchBar)
  .directive('nvFilePreview', nvFilePreview)
  .directive('nvFileUpload', nvFileUpload)
  .directive('nvFileUploadProgress', nvFileUploadProgress)
  .directive('nvFroalaEditor', nvFroalaEditor)
  .directive('nvFroalaOrigami', nvFroalaOrigami)
  .directive('nvGoogleLocationAutocomplete', nvGoogleLocationAutocomplete)
  .directive('nvHeaderPageLinks', nvHeaderPageLinks)
  .directive('iframe', iframe)
  .directive('nvIconUnreadBadge', nvIconUnreadBadge)
  .directive('nvIndividualSubmission', nvIndividualSubmission)
  .directive('nvInitiateCallback', nvInitiateCallback)
  .directive('nvInputColorPicker', nvInputColorPicker)
  .directive('nvInputWithLabel', nvInputWithLabel)
  .directive('nvIntervalSwitchItem', nvIntervalSwitchItem)
  .directive('nvJwPlayer', nvJwPlayer)
  .directive('nvError', nvError)
  .directive('nvL1CourseHeader', nvL1CourseHeader)
  .directive('nvL3HeaderTeam', nvL3HeaderTeam)
  .directive('nvLeaderboardTableRows', nvLeaderboardTableRows)
  .directive('nvLectureComponent', nvLectureComponent)
  .directive('nvLectureComponentDeadline', nvLectureComponentDeadline)
  .directive('nvLectureVideoMainBody', nvLectureVideoMainBody)
  .directive('nvLhs', nvLhs)
  .directive('nvMax', nvMax)
  .directive('nvMin', nvMin)
  .directive('nvMaxLength', nvMaxLength)
  .directive('nvAriaAnchor', nvAriaAnchor)
  .directive('nvModalPagePagination', nvModalPagePagination)
  .directive('nvMultiselectDropdown', nvMultiselectDropdown)
  .directive('nvNewObjectsAbove', nvNewObjectsAbove)
  .directive('nvNewTimelineItem', nvNewTimelineItem)
  .directive('nvTimelineItemCardView', nvTimelineItemCardView)
  .directive('nvNotificationsPopover', nvNotificationsPopover)
  .directive('nvNumberConversion', nvNumberConversion)
  .directive('nvOnScroll', nvOnScroll)
  .directive('nvPixelOnly', nvPixelOnly)
  .directive('nvPointsDecayPopover', nvPointsDecayPopover)
  .directive('nvPrivacyLink', nvPrivacyLink)
  .directive('nvProfilePicture', nvProfilePicture)
  .directive('nvProgressIcon', nvProgressIcon)
  .directive('nvQuestionTemplate', nvQuestionTemplate)
  .directive('nvQuizCorrectAnswer', nvQuizCorrectAnswer)
  .directive('nvQuizPoints', nvQuizPoints)
  .directive('nvQuizQuestion', nvQuizQuestion)
  .directive('nvRatioMinHeight', nvRatioMinHeight)
  .directive('nvRecentActivity', nvRecentActivity)
  .directive('nvReportForm', nvReportForm)
  .directive('nvReportSection', nvReportSection)
  .directive('nvRhs', nvRhs)
  .directive('nvRightClick', nvRightClick)
  .directive('nvScrollTo', nvScrollTo)
  .directive('nvScrollToInModal', nvScrollToInModal)
  .directive('nvSearchInput', nvSearchInput)
  .directive('nvSelectDropdown', nvSelectDropdown)
  .directive('nvSetFocus', nvSetFocus)
  .directive('nvSetVisibility', nvSetVisibility)
  .directive('nvShowAllToggle', nvShowAllToggle)
  .directive('nvShowOnFocus', nvShowOnFocus)
  .directive('nvSimpleProfilePicture', nvSimpleProfilePicture)
  .directive('nvStatementAndCertificateCard', nvStatementAndCertificateCard)
  .directive('nvSubmissionEditOptions', nvSubmissionEditOptions)
  .directive('nvSubmissionGalleryCard', nvSubmissionGalleryCard)
  .directive('nvSubmissionsCarousel', nvSubmissionsCarousel)
  .directive('nvSubmitButtonValidationPopover', nvSubmitButtonValidationPopover)
  .directive('nvTeamCard', nvTeamCard)
  .directive('nvTeamHeader', nvTeamHeader)
  .directive('nvTextFieldPrefix', nvTextFieldPrefix)
  .directive('nvTimeEstimates', nvTimeEstimates)
  .directive('nvTimedExamLimit', nvTimedExamLimit)
  .directive('nvTimelineItem', nvTimelineItem)
  .directive('nvTimelineLecturePageItem', nvTimelineLecturePageItem)
  .directive('nvTimelineSectionHeader', nvTimelineSectionHeader)
  .directive('nvTimelineSubsectionHeader', nvTimelineSubsectionHeader)
  .directive('nvTimelineWrapperLecturePage', nvTimelineWrapperLecturePage)
  .directive('nvTodoRow', nvTodoRow)
  .directive('nvToggleFocus', nvToggleFocus)
  .directive('nvUniqueRoleName', nvUniqueRoleName)
  .directive('nvUniqueEntry', nvUniqueEntry)
  .directive('nvUnreadBadge', nvUnreadBadge)
  .directive('nvUploadedFile', nvUploadedFile)
  .directive('nvUrlField', nvUrlField)
  .directive('nvValidateOtherOnBlur', nvValidateOtherOnBlur)
  .directive('nvValidationPopover', nvValidationPopover)
  .directive('nvValidationPopoverBody', nvValidationPopoverBody)
  .directive('nvVideoDownload', nvVideoDownload)
  .directive('nvVideoList', nvVideoList)
  .directive('oeContentMath', oeContentMath)
  .directive('oeMention', oeMention)
  .component('nvVideoPreviewUnavailable', nvVideoPreviewUnavailable)
  .directive('validateOnBlur', validateOnBlur)
  .directive('nvLiDisabled', nvLiDisabled)
  .directive('nvRoleBadge', nvRolesBadge)
  .directive('nvSubmissionApprovalNewComment', nvSubmissionApprovalNewComment)
  .directive('nvDropdownItem', nvDropdownItem)
  .directive('nvReactComponent', nvReactComponent)
  .directive('nvFirefoxAutomaticScrollToFocusedElement', NvFirefoxAutomaticScrollToFocusedElement)
  .directive('primaryBadge', PrimaryBadge)
  .directive('nvTranslationButton', nvTranslationButton)
  .factory('AccountSettingsResource', AccountSettingsResource)
  .factory('AggregatePeerReviewModel', AggregatePeerReviewModel)
  .factory('AlertMessages', AlertMessages)
  .factory('AnnouncementModel', AnnouncementModel)
  .factory('AnnouncementsManager', AnnouncementsManager)
  .factory('AnnouncementsResources', AnnouncementsResources)
  .factory('AttachmentLectureComponentModel', AttachmentLectureComponentModel)
  .factory('ImageLectureComponentModel', ImageLectureComponentModel)
  .factory('AccordionLectureComponentModel', AccordionLectureComponentModel)
  .factory('StyledLinkLectureComponentModel', StyledLinkLectureComponentModel)
  .factory('ProfileCompletionComponentModel', ProfileCompletionComponentModel)
  .factory('AttachmentsResources', AttachmentsResources)
  .factory('AudioListLectureComponentModel', AudioListLectureComponentModel)
  .factory('AuthenticationInterceptor', AuthenticationInterceptor)
  .factory('BlurbBackgroundImageLectureComponentModel', BlurbBackgroundImageLectureComponentModel)
  .factory('BlurbSideImageLectureComponentModel', BlurbSideImageLectureComponentModel)
  .factory('BlurbTopImageLectureComponentModel', BlurbTopImageLectureComponentModel)
  .factory('BookmarkModel', BookmarkModel)
  .factory('BookmarkResources', BookmarkResources)
  .factory('ChangeCaseInterceptor', ChangeCaseInterceptor)
  .factory('CommentModel', CommentModel)
  .factory('CommentsResources', CommentsResources)
  .factory('ConfettiAnimation', ConfettiAnimation)
  .factory('ConfirmationOverlays', ConfirmationOverlays)
  .factory('ContentTemplateBaseModel', ContentTemplateBaseModel)
  .factory('ConversationModel', ConversationModel)
  .factory('ConversationsManager', ConversationsManager)
  .factory('ConversationsResource', ConversationsResource)
  .factory('CountdownTimerManager', CountdownTimerManager)
  .factory('CountdownTimerModel', CountdownTimerModel)
  .factory('CourseHomeManager', CourseHomeManager)
  .factory('CourseHomeModel', CourseHomeModel)
  .factory('CourseHomeResources', CourseHomeResources)
  .factory('CourseHomeRhsManager', CourseHomeRhsManager)
  .factory('CourseModel', CourseModel)
  .factory('CourseRoleModel', CourseRoleModel)
  .factory('CourseRolesManager', CourseRolesManager)
  .factory('CourseRolesResource', CourseRolesResource)
  .factory('CoursesManager', CoursesManager)
  .factory('CoursesResource', CoursesResource)
  .factory('CurrentCourseManager', CurrentCourseManager)
  .factory('CurrentPermissionsManager', CurrentPermissionsManager)
  .factory('CurrentUserManager', CurrentUserManager)
  .factory('CustomDownloadsManager', CustomDownloadsManager)
  .factory('CustomDownloadsModel', CustomDownloadsModel)
  .factory('CustomDownloadsResources', CustomDownloadsResources);
app.factory('DashboardManager', DashboardManager)
  .factory('DashboardModel', DashboardModel)
  .factory('DashboardResources', DashboardResources)
  .factory('DirectoryManagerInterface', DirectoryManagerInterface)
  .factory('DiscussionLectureComponentModel', DiscussionLectureComponentModel)
  .factory('TeamDiscussionLectureComponentModel', TeamDiscussionLectureComponentModel)
  .factory('AbstractDiscussionLectureComponentModel', AbstractDiscussionLectureComponentModel)
  .factory('PollLectureComponentModel', PollLectureComponentModel)
  .factory('DiscussionsManager', DiscussionsManager)
  .factory('DiscussionsResources', DiscussionsResources)
  .factory('EmailUnsubscriptionsResource', EmailUnsubscriptionsResource)
  .factory('EmailVerificationResource', EmailVerificationResource)
  .factory('EmbedLectureComponentModel', EmbedLectureComponentModel)
  .factory('WebLinkLectureComponentModel', WebLinkLectureComponentModel)
  .factory('WebEmbedLectureComponentModel', WebEmbedLectureComponentModel)
  .factory('EvaluationsTaskManager', EvaluationsTaskManager)
  .factory('EvaluationsTaskModel', EvaluationsTaskModel)
  .factory('EvaluationsTaskResources', EvaluationsTaskResources)
  .factory('ExerciseLectureComponentModel', ExerciseLectureComponentModel)
  .factory('ExerciseModel', ExerciseModel)
  .factory('ExercisesManager', ExercisesManager)
  .factory('ExercisesResources', ExercisesResources)
  .factory('ExerciseSkillsRatingLectureComponentModel', ExerciseSkillsRatingLectureComponentModel)
  .factory('ExternalToolLectureComponentModel', ExternalToolLectureComponentModel)
  .factory('FilterCoursesManager', FilterCoursesManager)
  .factory('FlaggingModel', FlaggingModel)
  .factory('FlyOutPanelManager', FlyOutPanelManager)
  .factory('FlyoutModalManager', FlyoutModalManager)
  .factory('GroupDirectoryManager', GroupDirectoryManager)
  .factory('GroupFormationLectureComponentModel', GroupFormationLectureComponentModel)
  .factory('HeaderLectureComponentModel', HeaderLectureComponentModel)
  .factory('InstitutionAdminModel', InstitutionAdminModel)
  .factory('InstitutionAdminsResource', InstitutionAdminsResource)
  .factory('InstitutionsManager', InstitutionsManager)
  .factory('InstitutionsResource', InstitutionsResource)
  .factory('InteroperabilityRoutes', InteroperabilityRoutes)
  .factory('InvitationAndRequestModel', InvitationAndRequestModel)
  .factory('InvitationsAndRequestsManager', InvitationsAndRequestsManager)
  .factory('InvitationsAndRequestsResources', InvitationsAndRequestsResources)
  .factory('LTILectureComponentModel', LTILectureComponentModel)
  .factory('LeaderboardManager', LeaderboardManager)
  .factory('LeaderboardModel', LeaderboardModel)
  .factory('LeaderboardResources', LeaderboardResources)
  .factory('LearnerDirectoryManager', LearnerDirectoryManager)
  .factory('LearnerProfileManager', LearnerProfileManager)
  .factory('LearnerProfileModel', LearnerProfileModel)
  .factory('LearnerProfileResources', LearnerProfileResources)
  .service('LectureComponentBaseModel', LectureComponentBaseModel)
  .service('L4Modal', L4Modal)
  .factory('LectureComponentsHelper', LectureComponentsHelper)
  .factory('LecturePageBaseModel', LecturePageBaseModel)
  .factory('LecturePageComponentsResources', LecturePageComponentsResources)
  .factory('LecturePageManager', LecturePageManager)
  .factory('ReactLecturePageContext', ReactLecturePageContext)
  .value('ReactTimelineService', ReactTimelineService)
  .factory('LecturePageModel', LecturePageModel)
  .factory('LecturePagesResources', LecturePagesResources)
  .factory('LectureVideoListResources', LectureVideoListResources)
  .factory('LectureVideoModel', LectureVideoModel)
  .factory('LectureVideoResources', LectureVideoResources)
  .factory('LectureVideosManager', LectureVideosManager)
  .factory('LikertScaleQuestion', LikertScaleQuestion)
  .factory('LineDividerLectureComponentModel', LineDividerLectureComponentModel)
  .factory('LiveSessionLectureComponentModel', LiveSessionLectureComponentModel)
  .factory('LiveSessionModel', LiveSessionModel)
  .factory('LiveSessionResources', LiveSessionResources)
  .factory('MathjaxLoader', MathjaxLoader)
  .factory('MembershipRequestModel', MembershipRequestModel)
  .factory('MembershipRequestResource', MembershipRequestResource)
  .factory('MenteesManager', MenteesManager)
  .factory('MenteesResources', MenteesResources)
  .factory('MentionablesModel', MentionablesModel)
  .factory('MessageModel', MessageModel)
  .factory('MetaTagsService', MetaTagsService)
  .factory('ModalFocusService', ModalFocusService)
  .factory('MultipleChoiceMultipleAnswerQuestion', MultipleChoiceMultipleAnswerQuestion)
  .factory('MultipleChoiceQuestion', MultipleChoiceQuestion)
  .factory('NotificationModel', NotificationModel)
  .factory('NotificationsManager', NotificationsManager)
  .factory('NotificationsResources', NotificationsResources)
  .factory('NumberQuestion', NumberQuestion)
  .factory('OfflineManager', OfflineManager)
  .factory('PageLevelManager', PageLevelManager)
  .factory('PasswordsResource', PasswordsResource)
  .factory('PeerEvaluationLectureComponentModel', PeerEvaluationLectureComponentModel)
  .factory('PeerReviewModel', PeerReviewModel)
  .factory('PostModel', PostModel)
  .factory('PostsResources', PostsResources)
  .factory('PrivatePeerEvaluationLectureComponentModel', PrivatePeerEvaluationLectureComponentModel)
  .factory('PubSubDiscussions', PubSubDiscussions)
  .factory('PublicPeerEvaluationLectureComponentModel', PublicPeerEvaluationLectureComponentModel)
  .factory('VideoPracticeSkillsRatingLectureComponentModel', VideoPracticeSkillsRatingLectureComponentModel)
  .factory('PusherChannels', PusherChannels)
  .factory('PusherManager', PusherManager)
  .factory('QuizLectureComponentModel', QuizLectureComponentModel)
  .factory('QuizModel', QuizModel)
  .factory('QuizzesManager', QuizzesManager)
  .factory('QuizzesResources', QuizzesResources)
  .factory('RailsRoutes', RailsRoutesFactory)
  .factory('ReadOnlyQuestion', ReadOnlyQuestion)
  .factory('RepliesResources', RepliesResources)
  .factory('ReplyModel', ReplyModel)
  .factory('ReportModel', ReportModel)
  .factory('ReportsManager', ReportsManager)
  .factory('ReportsResources', ReportsResources)
  .factory('RichTextComposerResource', RichTextComposerResource)
  .factory('RichTextLectureComponentModel', RichTextLectureComponentModel)
  .factory('RichTextQuestion', RichTextQuestion)
  .factory('S3UploadWrapper', S3UploadWrapper)
  .factory('ScormLectureComponentModel', ScormLectureComponentModel)
  .factory('ScrollFocusConnectorFactory', ScrollFocusConnectorFactory)
  .factory('ShortTextQuestion', ShortTextQuestion)
  .factory('StateManager', StateManager)
  .factory('StatementModel', StatementModel)
  .factory('StatementsManager', StatementsManager)
  .factory('StatementsResources', StatementsResources)
  .factory('SubmissionsDiscoveryLectureComponentModel', SubmissionsDiscoveryLectureComponentModel)
  .factory('SurveyLectureComponentModel', SurveyLectureComponentModel)
  .factory('TableNumberQuestion', TableNumberQuestion)
  .factory('TableShortTextQuestion', TableShortTextQuestion)
  .factory('TeachingTeamDirectoryManager', TeachingTeamDirectoryManager)
  .factory('TeamDirectoryManager', TeamDirectoryManager)
  .factory('TeamFormationLectureComponentModel', TeamFormationLectureComponentModel)
  .factory('TeamManager', TeamManager)
  .factory('TeamMembershipModel', TeamMembershipModel)
  .factory('TeamMembershipsResource', TeamMembershipsResource)
  .factory('TeamModel', TeamModel)
  .factory('TeamSetModel', TeamSetModel)
  .factory('TeamSetsResources', TeamSetsResources)
  .factory('TeamWorkspaceManager', TeamWorkspaceManager)
  .factory('TeamWorkspaceModel', TeamWorkspaceModel)
  .factory('TeamsResources', TeamsResources)
  .factory('TextLectureComponentModel', TextLectureComponentModel)
  .factory('TimedQuizLectureComponentModel', TimedQuizLectureComponentModel)
  .factory('TimedQuizzesManager', TimedQuizzesManager)
  .factory('TimelineDiscussionLectureComponentModel', TimelineDiscussionLectureComponentModel)
  .factory('TimelineExerciseLectureComponent', TimelineExerciseLectureComponent)
  .factory('TimelineExerciseSkillsRatingLectureComponent', TimelineExerciseSkillsRatingLectureComponent)
  .factory('TimelineExternalToolLectureComponentModel', TimelineExternalToolLectureComponentModel)
  .factory('TimelineGroupFormationLectureComponentModel', TimelineGroupFormationLectureComponentModel)
  .factory('TimelineLecturePageModel', TimelineLecturePageModel)
  .factory('TimelineLectureSectionModel', TimelineLectureSectionModel)
  .factory('TimelineLectureSubsectionModel', TimelineLectureSubsectionModel)
  .factory('TimelineLectureVideoModel', TimelineLectureVideoModel)
  .factory('TimelineLiveSessionLectureComponentModel', TimelineLiveSessionLectureComponentModel)
  .factory('TimelineModel', TimelineModel)
  .factory('TimelinePeerReviewLectureComponentModel', TimelinePeerReviewLectureComponentModel)
  .factory('TimelineQuizLectureComponentModel', TimelineQuizLectureComponentModel)
  .factory('TimelinePollLectureComponentModel', TimelinePollLectureComponentModel)
  .factory('TimelineSurveyLectureComponentModel', TimelineSurveyLectureComponentModel)
  .factory('TimelineTeamFormationLectureComponentModel', TimelineTeamFormationLectureComponentModel)
  .factory('TimelineTimedQuizLectureComponentModel', TimelineTimedQuizLectureComponentModel)
  .factory('TimelineProgressiveQuizLectureComponentModel', TimelineProgressiveQuizLectureComponentModel)
  .factory('TimelineVideoListLectureComponentModel', TimelineVideoListLectureComponentModel)
  .factory('TimelineVideoPracticeLectureComponentModel', TimelineVideoPracticeLectureComponentModel)
  .factory('TimelineProfileCompletionLectureComponentModel', TimelineProfileCompletionLectureComponentModel)
  .factory('TimelinePublicPracticeFeedbackCriteriaLectureComponentModel', TimelinePublicPracticeFeedbackCriteriaLectureComponentModel)
  .factory('TimelinesManager', TimelinesManager);
app.factory('TimelinesResources', TimelinesResources)
  .factory('TimezonesService', TimezonesService)
  .factory('TodoBaseModel', TodoBaseModel)
  .factory('TopicModel', TopicModel)
  .factory('TopicsResources', TopicsResources)
  .factory('UserAuthentication', UserAuthentication)
  .factory('UserCourseModel', UserCourseModel)
  .factory('UserCoursesResource', UserCoursesResource)
  .factory('UserManagementResources', UserManagementResources)
  .factory('UserManager', UserManager)
  .factory('UserModel', UserModel)
  .factory('UsersResources', UsersResources)
  .factory('VideoListLectureComponentModel', VideoListLectureComponentModel)
  .factory('VideoPracticeLectureComponentModel', VideoPracticeLectureComponentModel)
  .factory('PublicPracticeFeedbackCriteriaLectureComponentModel', PublicPracticeFeedbackCriteriaLectureComponentModel)
  .factory('MeetAndGreetLectureComponentModel', MeetAndGreetLectureComponentModel)
  .factory('VideosManager', VideosManager)
  .factory('nvCurrentPage', nvCurrentPage)
  .provider('nvUtil', nvUtil)
  .factory('EmbeddingsResource', EmbeddingsResource)
  .factory('SubmissionApprovalResource', SubmissionApprovalResource)
  .factory('MentoringsResource', MentoringsResource)
  .factory('OrgLevelProfileManager', OrgLevelProfileManager)
  .factory('MigratedFields', MigratedFields)
  .factory('LearningJourneyService', LearningJourneyService)

  .filter('nvDateRange', nvDateRange)
  .filter('nvManualEllipsis', nvManualEllipsis)
  .filter('nvNumberToAlphabet', nvNumberToAlphabet)
  .filter('nvSecondsToClockTime', nvSecondsToClockTime)
  .filter('orderEmptyLast', orderEmptyLast)
  .service('AnchorModifier', AnchorModifier)
  .service('RolesService', RolesService)
  .filter('unique', (_) => function (arr, field) {
    return _.uniq(arr, (a) => a[field]);
  });

app.factory('OfflineInterceptor', OfflineInterceptor)
  .config(($httpProvider) => {
    $httpProvider.interceptors.push('OfflineInterceptor');
  });


app.config(
  [
    '$httpProvider',
    'config',
    '$uibModalProvider',
    '$stickyStateProvider',
    '$locationProvider',
    'humps',
    '_',
    '$animateProvider',
    '$provide',
    'vcRecaptchaServiceProvider',
    function (
      $httpProvider,
      config,
      $uibModalProvider,
      $stickyStateProvider,
      $locationProvider,
      humps,
      _,
      $animateProvider,
      $provide,
      vcRecaptchaServiceProvider,
    ) {
      $locationProvider.hashPrefix('!');

      $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

      $httpProvider.interceptors.push('ChangeCaseInterceptor');
      $httpProvider.interceptors.push('AuthenticationInterceptor');

      $stickyStateProvider.enableDebug(false);

      $uibModalProvider.options.animation = false;

      $uibModalProvider.options.backdrop = 'static';

      $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);


      $provide.decorator('$state', ['$delegate', '$rootScope', '$q', function ($state, $rootScope, $q) {
        const prevGo = $state.go;

        $state.go = (to, params, options) => {
          options = options || {};

          /**
           * $state.go expecting a promise, So returning prevGo,
           * which has promise response
           */
          return prevGo(to, params, {
            ...options,
            isOwn: true,
          });
        };

        const prevTransitionTo = $state.transitionTo;

        $state.transitionTo = (to, toParams, transitionToOptionsWithNovoEdOptions) => {
          const { isOwn = false, ...options } = transitionToOptionsWithNovoEdOptions || {};
          let replacerPromise = null;

          const deRegister = $rootScope.$on('$stateChangeStart', (event, toState, eventToParams, fromState, fromParams) => {
            deRegister();

            function replaceNavigationPromise(promise) {
              event.preventDefault();
              replacerPromise = promise;
            }

            // Emitting '$stateChangeStartWithPromiseReplacement' and passing
            // 'replaceNavigationPromise' so we can replace it in the
            // 'prevTransitionTo' returned promise.
            $rootScope.$broadcast(
              '$stateChangeStartWithPromiseReplacement',
              toState,
              eventToParams,
              fromState,
              fromParams,
              replaceNavigationPromise,
            );
          });

          return prevTransitionTo(to, toParams, options).catch(
            err => {
              if (replacerPromise !== null) {
                const optionsForNotOwnNavigation = options.location ? options : { ...options, location: true };

                return replacerPromise.then(() => prevTransitionTo(
                  to,
                  toParams,
                  isOwn ? options : optionsForNotOwnNavigation,
                ));
              }

              return $q.reject(err);
            },
          );
        };

        return $state;
      }]);

      // Decorating $uibModal to be in sync with react modal manager stack to
      // prevent strange bugs.
      $provide.decorator('$uibModal', ['$delegate', function ($uibModal) {
        const originalOpen = $uibModal.open;

        $uibModal.open = (modalOptions) => {
          // React bootstrap modal manager
          const manager = getModalManager();

          const instance = {
            // Dummy elements, used for nothing, just so that the code doesn't
            // break.
            dialog: document.createElement('div'),
            backdrop: document.createElement('div'),
          };
          // Any time we open an angular modal, we need to register it in the
          // react manager stack so that forced focus works properly.
          // Enforce focus depends on what isTopModal returns so if we also add
          // angular modals to stack this behaves correctly.
          // https://github.com/react-bootstrap/react-overlays/blob/v4.1.0/src/Modal.tsx#L253
          manager.add(
            instance,
            // Dummy element, used for nothing, just so that code doesn't break.
            document.createElement('div'),
          );

          const modalInstance = originalOpen(modalOptions);

          const originalClosed = modalInstance.closed;
          modalInstance.closed = originalClosed.then(() => {
            // Removing the entry from manager when modal is closed.
            manager.remove(instance);
          });

          return modalInstance;
        };

        return $uibModal;
      }]);

      $provide.decorator('amDateFormatFilter', ['$delegate', '$translate', function ($delegate, $translate) {
        const srcFilter = $delegate;

        const extendsFilter = function (...args) {
          // TODO: change all references to use the moment codes
          // this was a quick solution to normalize our date and times
          if (args[1] && args[1].startsWith('MOMENT.')) {
            args[1] = novoEdMomentMapping[args[1]];
          }

          return srcFilter.apply(this, args);
        };

        extendsFilter.$stateful = true;

        return extendsFilter;
      }]);

      vcRecaptchaServiceProvider.setDefaults({
        key: config.google.recaptcha,
        lang: 'en',
      });
    },
  ],
)
  .run(
    /* eslint-disable-next-line max-params */
    (
      $window,
      $rootScope,
      $state,
      StateManager,
      UserAuthentication,
      config,
      PageLevelManager,
      CurrentCourseManager,
      MentionablesModel,
      CurrentUserManager,
      CurrentPermissionsManager,
      MetaTagsService,
      nvCurrentPage,
      OfflineManager,
      $timeout,
      _,
      $document,
      InstitutionsManager,
      $uibModalStack,
      moment,
      $cookies,
      $templateCache,
      FlyOutPanelManager,
      FlyoutModalManager,
      TeamWorkspaceManager,
      $location,
    ) => {
      $rootScope._ = _;
      $rootScope.CurrentCourseManager = CurrentCourseManager;
      $rootScope.InstitutionsManager = InstitutionsManager;
      $rootScope.CurrentUserManager = CurrentUserManager;
      $rootScope.MetaTagsService = MetaTagsService;
      $rootScope.StateManager = StateManager;
      $rootScope.nvCurrentPage = nvCurrentPage;
      // window.viewportUnitsBuggyfill.init({ refreshDebounceWait: 250 });

      StateManager.initUnsavedChangesCheckBeforeLeaving();

      // Set up our Angular template cache with compiled jade files.
      // Most of the complexity here is just to power hot reloading.
      // See https://webpack.js.org/guides/dependency-management/ for a light overview of require.context().

      /** Insert html source into $templateCache
       * @param {string} name Template name
       * @param {string} module Compiled jade file HTML source
       */
      function updateTemplateCache(name, module, isReload) {
        const cachedName = `${name.slice(2, name.length - 5)}.html`;
        $templateCache.put(cachedName, module);
      }

      const context = requireTemplatesContext();
      const modules = {};

      // resolves the require for each jade file found in requireTemplatesContext(), and inserts the
      // built html into the templateCache
      context.keys().forEach((key) => {
        const module = context(key);
        modules[key] = module;
        updateTemplateCache(key, module, false);
      });

      // Hot reload by redoing the above logic for only the specific jade files changed
      if (module.hot) {
        module.hot.accept(context.id, () => {
          const reloadedContext = requireTemplatesContext();
          const changedModules = reloadedContext.keys()
            .map((key) => [key, reloadedContext(key)])
            .filter((reloadedModule) => modules[reloadedModule[0]] !== reloadedModule[1]);
          changedModules.forEach((module) => {
            modules[module[0]] = module[1];
            updateTemplateCache(module[0], module[1], true);
          });

          // Reload current state to show the new compiled template
          $state.reload();
        });
      }

      // Returns a webpack context object representing recursive require()'s for all jade files
      function requireTemplatesContext() {
        // Grab templates from the dist folder if in production as these have had references to local
        // assets replaces w/ the correct paths to prod locations on S3 (etc).
        // Looks like Webpack breaks if you use a template string for the first arg of context()
        if (process.env.NODE_ENV === 'production') {
          return require.context('html-loader!pug-html-loader!../.tmp/prodTemplates', true, /\.jade$/);
        }
        return require.context('html-loader!pug-html-loader!../app', true, /\.jade$/);
      }

      OfflineManager.initialize();

      let googleAnalyticsId = null;
      const insertGoogleAnalyticsIfUnset = () => {
        // the analytics identifier gets overwritten when inside a course - so we need to look on both Institution and CurrentPermissions mgr.
        const currentAnalyticsIdentifier = InstitutionsManager.institution?.analyticsIdentifier || CurrentPermissionsManager.course?.institution?.analyticsIdentifier;
        if (!googleAnalyticsId && currentAnalyticsIdentifier) {
          insertGoogleAnalytics(currentAnalyticsIdentifier);
          googleAnalyticsId = currentAnalyticsIdentifier;
        }
      };
      $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
        const currentCourse = toParams.catalogId ? CurrentUserManager.coursesHashByCatalogId[toParams.catalogId] : null;
        $rootScope.title = toState.title;
        StateManager.pushEnteredState(toState, toParams);

        insertGoogleAnalyticsIfUnset();

        // close any open modals
        $uibModalStack.dismissAll('cancel');

        // Control flyout panel view and hide functionality on state change success
        if ($state.includes('flyout-panel')) {
          // When the current state includes 'flyout-panel'(top-most parent
          // Of the flyout panel). Display Flyout panel if it is not opened yet
          if (!FlyOutPanelManager.isVisible()) {
            // Storing the from state and parmas so we can go back to that state
            // when closing the flyout panel
            FlyOutPanelManager.showPanel(fromState, fromParams);
          }
        } else if ((!toState.data.level || toState.data.level < 3.5) && FlyOutPanelManager.isVisible()) {
          // Hide the portal layout when moving to a page less than L4 and Not a Subchild of flyout-panel
          // Since we can open L4 modal on top of the flyout panel. No need to
          // Close the flyout panel
          FlyOutPanelManager.hidePanel();
        }

        if (FlyoutModalManager.isVisible()
          // This is to handle a special case. Transistions from L3 to L4 will
          // have two state changes. Flyout need to be close only once.
          //
          // Also consider other case, User did these actions from a L3 page -
          // Opened FlyoutPanel L3.5 -> Opened FlyoutModal
          // Opening FlyoutModal need to close FlyoutPanel and it will try to
          // change the stage to previous state, which is L3 page.
          // A direct request to L3, which will also trigger a stage change to L4
          // No need to close flyout on this transistion from L3 to L4
          && fromState?.data?.level !== 4
          && !(fromState?.data?.level === 3 && toState?.data?.level === 4)) {
          FlyoutModalManager.closeFlyout();
        }

        // keep track of where the close button in different levels should go to
        PageLevelManager.push(fromState, fromParams, toState, toParams);

        MentionablesModel.resetSearchResults();

        // No need to scroll Up if the user is going to a L3.5 or L4 page
        // Only scrolling up level < 3 and coming from a state where level < 3
        if (toState.data.level <= 3 && (fromState.data?.level <= 3)) {
          $('#main-panel').scrollTop(0);
          $('.learner-dashboard').scrollTop(0);
          $('.lightbox-panel').scrollTop(0);
          $('#modal-content-wrapper.modal-content-wrapper').scrollTop(0);
        }
        // need dealy because the current window location may be slow
        $timeout(() => {
          if (googleAnalyticsId && !InstitutionsManager.institution?.analyticsIdentifier) {
            resetGoogleAnalytics();
          }
          insertGoogleAnalyticsIfUnset();
          sendPageView();
          if (currentCourse) {
            if ((toState.data.level !== 4 && toState.data.level !== 3.5) || (CurrentCourseManager.course && currentCourse.id === CurrentCourseManager.course.id)) {
              sendStateMetaDataToPendo(currentCourse);
              // sendPageView(currentCourse.institution.id, currentCourse, false);
            } else {
              sendStateMetaDataToPendo(currentCourse);
              // sendPageView(currentCourse.institution.id, currentCourse, true);
            }
          }
        });

        function sendStateMetaDataToPendo(course) {
          pendoTrack($state && $state.current ? $state.current.name : window.location.hash,
            _.extend({ account: window.location.host, stateParams: $state.params }, CurrentCourseManager.getPendoProperties()));
        }

        function sendPageView() {
          if (window.gtag) {
            window.gtag({ event: 'pageview', page: { path: window.location.toString() } });
          }
        }

        // DEBUGGING ROUTE ISSUES
        // console.log(`$stateChangeSuccess to ${toState.name}- fired once the state transition is complete.`, toState, toParams);
      });

      // DEBUGGING ROUTE ISSUES
      // $rootScope.$on('$stateChangeError', console.error.bind(console));

      FlyOutPanelManager.initialize();

      $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
        // DEBUGGING ROUTE ISSUES
        // console.log(`$stateChangeStart to ${toState.name}- fired when the transition begins. toState,toParams : \n`, toState, toParams);

        StateManager.lastStateAttempted = toState;
        StateManager.paramsForLastStateAttempted = toParams;
        StateManager.catalogId = toParams.catalogId;

        if (toState.parent === 'modal-page' || toState.data.level === 4) {
          // save the focused element: accessibility support
          $rootScope.focusedElementPreL4 = $document[0].activeElement;
        } else if (toState.data.level === 3.5 && (fromState?.data?.level !== 3.5)) {
          // Opening a new flyout panel. save last focused element. accessibility support for flyout manager
          $rootScope.focusedElementPreFlyout = $document[0].activeElement;
        }
        /**
         * When the user go to the flyout panel directly ( eg: from an email ) or coming from login page
         * Go to dashboard and then open the flyout Panel on top of the dashboard
         */
        if ((fromState.name === '' || fromState.parent === 'users.authentications.verify-user') && toState.data.level === 3.5) {
          event.preventDefault();
          /**
           * If CurrentUserManager is not set and user is not logged in, Call Auth API
           * and check whether the user is logged in or not. If not logged in, go to auth screen
           */
          if (!CurrentUserManager.user || !CurrentUserManager.hasLoggedIn()) {
            UserAuthentication.verifyAuthenticated().then(
              () => {
                $('.loading-screen').hide();
                if (CurrentUserManager.hasLoggedIn()) {
                  // Clear Current Course Manager Since going to dashboard
                  CurrentCourseManager.resetCourse();
                  CurrentUserManager.resetCurrentUserCourse();

                  /** If the user is from the expanded view of a workspace or
                   * the user directly going to workspace (eg: from an email) then
                   * open the workspace in full page.
                   */
                  if ((toState.name === 'team-workspace' || toState.parent === 'team-workspace')
                    && fromState.name === '') {
                    $state.go(`${toState.name}-full`, toParams);
                  } else {
                    $state.go('dashboard', null, { location: false }).then(() => {
                      $state.go(toState, toParams);
                    });
                  }
                } else {
                  $state.go('users.authentications.sign-in');
                }
              },
            );
          } else {
            // Clear Current Course Manager Since going to dashboard
            CurrentCourseManager.resetCourse();
            CurrentUserManager.resetCurrentUserCourse();

            $state.go('dashboard', null, { location: false }).then(() => {
              $state.go(toState, toParams);
            });
          }
        } else if (toState.parent === 'modal-page' || toState.data.level === 4) {
          // modal page: go to single page if no previous state or if prev state is L3 version of L4 and are going to another L4 (https://novoed.atlassian.net/browse/NOV-30304)

          const modalPageVersionOfFromState = $state.get(`${fromState.name.split('-page')[0]}-modal`);
          /**
           * Prevent switching from an L4 to and L3 when navigating from an org admin
           * dashboard page or org mentor user enrollment modal.
           */
          const isFromOrgAdminOrOrgMentorModal = (fromState.parent === 'institution-modal-page'
            || (CurrentPermissionsManager.hasOrgMentorPermissions()
              && fromState.name === 'user-enrollment-details-modal'));
          // Switch from an L4 to an L3 if the user isn't enrolled in this course due to
          // https://novoed.atlassian.net/browse/NOV-52988
          const modalStateToUnenrolledCourse = !!toParams.catalogId && !CurrentUserManager.coursesHashByCatalogId[toParams.catalogId];

          // This checking is needed since we are making the L3 requirement for L4 page is optional.
          // Right now, we can have L4 pages that loads directly above the dashboard when accessing directly
          // So we need this checking when an unauthorised user accesssing the L4 ( that doesn't have an L3 page ) directly
          // The learner will be redirected to login page and once login is successfull, they will get redirect to the
          // L4 state that they were trying to access before. In that case also,
          // we have to load the dashboard first before loading L4 state
          const isFromLoginPage = fromState.parent === 'users.authentications.verify-user';

          if (!isFromOrgAdminOrOrgMentorModal
            && (fromState.name === ''
              || isFromLoginPage
              || modalStateToUnenrolledCourse
              || modalPageVersionOfFromState
            )) {
            const L3ToState = `${toState.name.split('-modal')[0]}-page`;

            // instead go to page version if exists or go to dashboard and open L4 on top
            event.preventDefault();


            // Check modal page verion exists
            const pageVersionExists = $state.get(L3ToState);

            const queryParams = $location.search();
            if (pageVersionExists) {
              // instead, transition to single page version of the same state
              $state.go(L3ToState, toParams, { location: false });
            } else if (!CurrentUserManager.user || !CurrentUserManager.hasLoggedIn()) {
              /**
               * If CurrentUserManager is not set and user is not logged in, Call Auth API
               * and check whether the user is logged in or not. If not logged in, go to auth screen
               *
               * Usually authentication is done on the route resolve. We have to verify Authentication here
               * because we are loading the L4 page( that doesn't have an L3 ) after going to the dashboard. .
               * So when an unauthorised user go to the dashboard page,
               * he will be redirected to login page from dashboard route resolve and after completing user
               * verification, the learner will be redirected to the dashboard since that was the last state attemted before login.
               * So to avoid that, we are verifying the user before going to the dashboard
               */
              UserAuthentication.verifyAuthenticated().then(
                () => {
                  $('.loading-screen').hide();
                  if (CurrentUserManager.hasLoggedIn()) {
                    // Clear Current Course Manager Since going to dashboard
                    CurrentCourseManager.resetCourse();
                    CurrentUserManager.resetCurrentUserCourse();
                    $state.go('dashboard', null, { location: false }).then(() => {
                      $state.go(toState, _.extend(toParams, queryParams));
                    });
                  } else {
                    // Extend last state param with query params since we may need to use them later
                    StateManager.paramsForLastStateAttempted = _.extend(toParams, queryParams);
                    // Go to login page
                    $state.go('users.authentications.sign-in');
                  }
                },
              );
            } else {
              // Clear Current Course Manager Since going to dashboard
              CurrentCourseManager.resetCourse();
              CurrentUserManager.resetCurrentUserCourse();

              $state.go('dashboard', null, { location: false }).then(() => {
                $state.go(toState, _.extend(toParams, queryParams));
              });
            }
          }
        } else if (TeamWorkspaceManager.isTeamWorkspaceFull
          && (toState.name === 'team-workspace' || toState.parent === 'team-workspace')) {
          /** Clicking another workspace from a full page view of a workspace,
           * moving to full page view of that clicked workspace.
           */
          event.preventDefault();
          $state.go(`${toState.name}-full`, toParams);
        }
      });

      $rootScope.$on('$stateChangeError', (evt, to, toParams, from, fromParams, error) => {
        if (error?.redirectUrl) {
          // for redirecting to different domain
          evt.preventDefault();
          $window.location = error?.redirectUrl;
        } else if (error?.redirectTo) {
          $state.go(error.redirectTo, error.redirectParams);
        } else if (error) {
          // Rethrow the error if it's not an intentional redirect. This ensures it's visible in dev tools and also gets reported to NR
          throw error;
        } else {
          Sentry.configureScope((scope) => {
            scope.setExtras({
              evt,
              to,
              toParams,
              from,
              fromParams,
            });
          });
          Sentry.captureException(new Error('$stateChangeError event with no error object'));
        }
      });

      $rootScope.$on('$stateNotFound', (event, unfoundState, fromState, fromParams) => {
      });

      $rootScope.$on('unauthorized', (event, rejection) => {
        UserAuthentication.setLastAttempted();
        if (!StateManager.lastStateAttempted.name.includes('users.authentications') && StateManager.lastStateAttempted.data.requiresLogin !== false) {
          UserAuthentication.beginAuthentication();
        }
      });


      const srcFormat = moment.prototype.format;

      moment.prototype.format = function (...args) {
        if (args[0] && args[0].startsWith('MOMENT.')) {
          args[0] = novoEdMomentMapping[args[0]];
        }

        return srcFormat.apply(this, args);
      };

      CurrentUserManager.updateOrigamiLanguage($cookies.get('platform_language') || config.defaultPlatformLanguage);
    },
  )
  .filter('unique', (
    _,
  ) => function (arr, field) {
    return _.uniq(arr, (a) => a[field]);
  });

// These need to run after we've humps-ified the config files above
constants(app);
s3NameSpaces(app);

app.config(routes);
app.config(athenaRoutes);
app.config(configureTranslateProvider);

if (module.hot) {
  module.hot.accept();
}

configAxios();

// Add a convenience funtion to the global object in dev mode that lets us access/debug our
// singletons
if (process.env.NODE_ENV === 'development') {
  $(document).ready(() => {
    window.get = angular.element(document).injector().get;
  });
}

export default app;
