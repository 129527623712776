/* eslint-disable react/no-array-index-key */
import t from 'react-translate';
import { css } from '@emotion/react';
import {
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import NvIcon from 'shared/components/nv-icon';
import { novoEdBlue } from 'athena/styles/colors';
import ClickableContainer from 'components/clickable-container';
import {
  MentorshipProgramProfileQuestion,
  ProgramLevelQuestion,
  QuestionType,
} from 'redux/schemas/models/mentoring-program-profile';
import uuid from 'react-uuid';
import { useAppDispatch } from 'redux/store';
import { ProgramProfileQuestionType, addNewProgramLevelQuestionDraft, getMentorshipProgramProfileQuestions, updateMentorshipProgramProfileQuestion } from 'redux/actions/mentoring-program-profile';
import { useContext, useEffect, useMemo } from 'react';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { setSaveStatus } from 'redux/actions/mentoring-programs';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { wrapThunkAction } from 'redux/utils';
import { useInView } from 'react-intersection-observer';
import { DRAFT_PREFIX, PAGE_SIZE } from 'athena/components/mentoring-program/constants';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { getMentorshipProgramProfileDraftQuestions, getMentorshipProgramProfileQuestionsList } from 'redux/selectors/mentorship-program-profile';
import { config } from '@config/pendo.config.json';
import { useSelector } from 'react-redux';
import ProgramLevelField from './program-level-field';
import { GetProgramProfileQuestionParams } from '../types';

const rowStyle = css`
  display: grid;
  gap: ${threeQuartersSpacing}px;
  grid-template-columns: auto 30% 10% 16px;
  padding: 0 ${standardSpacing}px;

  .cell:last-child {
    justify-self: center;
  }
`;

const styles = css`
  padding-top: ${largeSpacing}px;

  .headers {
    ${rowStyle};
  }
  .field-row {
    ${rowStyle};
  }
  .add-new-question {
    gap: ${quarterSpacing}px;
    color: ${novoEdBlue};
  }
`;

const getNewQuestionDraft = () => ({
  tempId: `${DRAFT_PREFIX}-${uuid()}`,
  questionType: QuestionType.QUESTION,
});

const getQuestionList = (question: MentorshipProgramProfileQuestion) => {
  const { profileQuestion } = question;
  const { questionList } = (profileQuestion as ProgramLevelQuestion) || {};
  return questionList;
};

const sortByQuestionIndex = (firstQuestion: MentorshipProgramProfileQuestion, secondQuestion: MentorshipProgramProfileQuestion) => {
  const { questionIndex: firstQuestionIndex } = getQuestionList(firstQuestion) || {};
  const { questionIndex: secondQuestionIndex } = getQuestionList(secondQuestion) || {};
  return firstQuestionIndex - secondQuestionIndex;
};

const ProgramLevelFieldsSection = () => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const headers = [
    t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.HEADERS.QUESTION(),
    t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.HEADERS.QUESTION_TYPE(),
    t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.HEADERS.REQUIRED(),
  ];
  const { ref: endRef, inView } = useInView();
  const draftQuestions = useSelector(getMentorshipProgramProfileDraftQuestions);
  const params = useMemo(
    () => ({
      type: ProgramProfileQuestionType.PROGRAM_LEVEL,
      pageSize: PAGE_SIZE,
    }),
    [],
  );

  const { reset, result: programLevelQuestions, loadMore } = usePaginatedApi<
  MentorshipProgramProfileQuestion,
  GetProgramProfileQuestionParams
  >(
    p => dispatch(
      getMentorshipProgramProfileQuestions({ mentorshipProgramId, ...p }),
    ).then(action => action.payload),
    params,
    getMentorshipProgramProfileQuestionsList,
  );

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [loadMore, inView]);


  const onAddQuestion = () => dispatch(addNewProgramLevelQuestionDraft({ newItem: getNewQuestionDraft() }));

  const addQuestionLabel = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.ADD_QUESTION();

  const updateQuestionIndex = (question: MentorshipProgramProfileQuestion, newQuestionIndex: number) => {
    dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
    wrapThunkAction(dispatch(updateMentorshipProgramProfileQuestion({
      questionId: question.id,
      mentorshipProgramId,
      mentorshipProgramProfileQuestion: {
        index: newQuestionIndex,
        isRequired: question.isRequired,
        questionText: getQuestionList(question).questionText,
        questionType: getQuestionList(question).type,
        type: ProgramProfileQuestionType.PROGRAM_LEVEL,
        responseOptions: getQuestionList(question).responseOptions.map(option => ({ optionContent: option.optionContent })),
      },
    }))).then(() => {
      dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
    })
      .finally(() => {
        setTimeout(() => {
          dispatch(setSaveStatus({ newStatus: null }));
        }, 3000);
      });
  };

  const swapQuestionIndex = (index: number, newIndex: number) => {
    const questionAtIndex = questionsSortedByQuestionIndex[index];
    const questionAtNewIndex = questionsSortedByQuestionIndex[newIndex];
    const firstQuestionIndex = getQuestionList(questionAtIndex)?.questionIndex;
    const secondQuestionIndex = getQuestionList(questionAtNewIndex)?.questionIndex;
    if (firstQuestionIndex !== undefined && secondQuestionIndex !== undefined) {
      updateQuestionIndex(questionAtIndex, secondQuestionIndex);
      updateQuestionIndex(questionAtNewIndex, firstQuestionIndex);
    }
  };

  const questionsSortedByQuestionIndex = useMemo(() => programLevelQuestions?.sort(sortByQuestionIndex), [programLevelQuestions]);

  return (
    <div css={styles} className='mb-4'>
      <div className='subtitle heading-5 mb-2'>
        {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.TITLE()}
      </div>
      <div className='description text-regular mb-4'>
        {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.DESCRIPTION()}
      </div>
      <div className='text-small semi-bold align-content-center headers'>
        {headers.map(header => (
          <div key={header} className='cell'>
            {header}
          </div>
        ))}
        <span />
      </div>
      {[...questionsSortedByQuestionIndex || [], ...draftQuestions]?.map((question, index) => {
        if (question) {
          return (
            <ProgramLevelField
              key={index}
              index={index}
              question={question}
              canMoveUp={index !== 0 && !question?.tempId}
              onMoveUp={() => swapQuestionIndex(index, index - 1)}
              canMoveDown={index !== questionsSortedByQuestionIndex?.length - 1 && !question.tempId}
              onMoveDown={() => swapQuestionIndex(index, index + 1)}
              onAddOrRemove={reset}
            />
          );
        }
        return null;
      })}
      <div ref={endRef} />
      <ClickableContainer
        onClick={onAddQuestion}
        className='add-new-question align-items-center semi-bold pt-2'
        aria-label={addQuestionLabel}
        data-qa={config.pendo.athena.mentorshipProgram.settings.programProfileSetup.createProgramLevelProfileQuestion}
      >
        <NvIcon icon='add' size='smallest' />
        <span>{addQuestionLabel}</span>
      </ClickableContainer>
    </div>
  );
};

export default ProgramLevelFieldsSection;
