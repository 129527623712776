import { novoEdBlue } from 'athena/styles/colors';
import ClickableContainer from 'components/clickable-container';
import { useState } from 'react';
import NvIcon from 'shared/components/nv-icon';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { css } from '@emotion/react';
import { config } from '@config/pendo.config.json';

const menuStyles = (showMenu: boolean) => css`
  .menu-options {
    display: ${showMenu ? 'block' : 'none'};
    position: absolute;
    left: -25px;
    top: 0;
    background: transparent;
    color: ${novoEdBlue};
  }
  .menu-icon {
    width: ${standardSpacing}px;
    height: ${standardSpacing}px;
    justify-self: center;
    transform: rotate(90deg);
  }
`;

type OptionsMenuProps = {
  ruleId: number;
  onDelete: (ruleId: number) => void;
};

const OptionsMenu = ({ ruleId, onDelete }: OptionsMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div
      css={menuStyles(showMenu)}
      className='d-inline-block position-relative'
    >
      <ClickableContainer onClick={() => onDelete(ruleId)} className='menu-options' data-qa={config.pendo.athena.mentorshipProgram.settings.matchingRules.deleteMatchingRule}>
        <NvIcon icon='trash' size='smallest' />
      </ClickableContainer>
      <ClickableContainer
        onClick={() => setShowMenu(show => !show)}
        className='menu-icon'
      >
        <NvIcon icon='more' size='smallest' />
      </ClickableContainer>
    </div>
  );
};

export default OptionsMenu;
