import { css } from '@emotion/react';
import { forwardRef, useRef, useEffect } from 'react';
import {
  popoverZIndex,
  halfSpacing,
} from 'styles/global_defaults/scaffolding';
import useClickOutside from 'shared/hooks/use-click-outside';
import { gray2, gray5, white } from 'styles/global_defaults/colors';
import { mergeRefs } from 'shared/react-utils';

type AccessibleOverlayProps = Omit<
React.ComponentProps<'div'>,
'tabIndex' | 'className'
> & {
  show: boolean;
  onClose: (closedWithKeyboard: boolean) => void;
};

const AccessibleOverlay = forwardRef<HTMLDivElement, AccessibleOverlayProps>(
  (props, ref) => {
    const { show, onClose, ...restProps } = props;

    const innerRef = useRef<HTMLDivElement>();
    const onCloseRef = useRef<Function>();
    onCloseRef.current = onClose;

    const styles = css`
      width: 340px;
      border-radius: 4px;
      background-color: ${white};
      border: 1px solid ${gray5};
      z-index: ${popoverZIndex};
      padding: ${halfSpacing}px;
      margin-top: 2px;

      .label {
        color: ${gray2};
      }
    `;

    useEffect(() => {
      if (innerRef.current) {
        innerRef.current.focus();
      }
    }, [show]);

    const handleKeyDown = e => {
      if (e.key === 'Escape') {
        onClose(true);
      }
    };

    useClickOutside(innerRef, () => onClose(false));

    return (
      <div
        css={styles}
        tabIndex={-1}
        className='react-app'
        onKeyDown={handleKeyDown}
        ref={mergeRefs(ref, innerRef)}
        {...restProps}
      />
    );
  },
);

export default AccessibleOverlay;
