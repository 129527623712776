import React from 'react';

import { MentoringProgramNormalized } from 'redux/schemas/models/mentoring-programs';

export type MentoringProgramContextValue = {
  isLoading: boolean;
  mentoringProgram?: MentoringProgramNormalized;
  countState: {
    result: any;
    isLoading: boolean;
    refresh: () => Promise<void>;
  },
};

const MentoringProgramContext = React.createContext<MentoringProgramContextValue>(null);

export default MentoringProgramContext;

export type MentoringProgramHomeUIContextValue = {
  withOverflowFlexGrowClass: string;
};

export const MentoringProgramHomeUIContext = React.createContext<MentoringProgramHomeUIContextValue>(null);
