import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import {
  get,
  FieldError,
  useFormContext,
  UseFormRegisterReturn,
} from 'react-hook-form';

import { mergeRefs } from 'shared/react-utils';
import { gray3 } from 'styles/global_defaults/colors';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { NvPopover } from 'shared/components/nv-popover';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import { textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';

export const LABEL_SIZE = textSmallLineHeight;

const inputContainerStyles = css`
  position: relative;

  .label {
    top: 0;
    left: 0;
    color: ${gray3};
    position: absolute;
    line-height: ${LABEL_SIZE}px;
  }
`;

type NativeInputProps = React.ComponentProps<'input'>;

/**
 * Props that we want to inherit from the native input props.
 */
// Just omitting "className", since it's now "inputClassName".
type NativeInputPropsInherited = Omit<NativeInputProps, 'className'>;

/**
 * Props is the combination of:
 * 1. Our inherited props that are directly passed to the input element via
 * "restProps"
 * 2: Our "additional" props which is any prop that is not from the native input
 * props.
 */
export type Props = NativeInputPropsInherited & {
  // "aria-label" attribute value of the input element.
  ariaLabel?: string,
  // Class name of the container element.
  className?: string,
  // Error of the input, using FieldError as the type of our errors, if you want
  // to display an error that doesn't come from a react-hook-form form use
  // "as FieldError". Example:
  // "error={{ message: 'This is my error' } as FieldError}"
  error?: FieldError,
  // Whether we want to use this component in a react-hook-form form.
  // NOTE: Requires "FormProvider" parent wrapper to work.
  withForm?: boolean,
  // If true, a label with the placeholder prop text will be rendered right
  // above the input and will only be visible when field has a value.
  withLabel?: boolean,
  // By default the label is only shown when input has no value, this is to
  // enforce label to always be there.
  labelAlwaysShown?: boolean,
  // If withLabel is used, the placeholder prop is used as label by default,
  // this prop is useful to override it and pass a custom label
  label?: string,
  // Class name of the input element.
  inputClassName?: string,
  // Whether we want the error to only be shown when the input is focused, if
  // "false" it will be always visible. "true" as default.
  errorOnTouching?: boolean,
  // Placement of the error popover.
  popoverPlacement?: Placement,
  // Emotion styles for overlay component.
  overlayStyles?: SerializedStyles,
  // Value of error popover "preventOverflow" prop.
  preventPopoverOverflow?: boolean,
  // display error popup immediatly, without the need for focus.
  showErrorWithoutFocus?: boolean,
  // force to display error state,
  // used to display the error even if the user hasn't touched the field
  forceShowErrorState?: boolean,
  // Show the error after pressing the key Enter
  showErrorOnEnter?: boolean,
};

/**
 * NovoEd text input component.
 * This component can be used as a controlled and uncontrolled component as well
 * as it can be hooked into a react-hook-form form if you pass the "withForm"
 * prop as "true".
 * If you use withForm make sure:
 *   - You don't pass the "value" prop or you'll be forcing the value of the
 *     input to be the one you are declaring even though it's connected to a
 *     react-hook-form form already.
 *   - You pass the "name" prop as well to make react-hook-form field registry
 *     process work.
 */
export const NvTextInput = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      // Native input props:
      name,
      value,
      onBlur,
      onFocus,
      onChange,
      onKeyDown,
      readOnly,
      required,
      placeholder,
      type = 'text',
      // Additional props:
      label,
      withLabel,
      ariaLabel,
      className,
      overlayStyles,
      inputClassName,
      withForm = false,
      error: propsError,
      errorOnTouching = true,
      labelAlwaysShown = false,
      popoverPlacement = 'top',
      preventPopoverOverflow = false,
      showErrorWithoutFocus,
      forceShowErrorState = false,
      showErrorOnEnter = false,
      ...restProps // Native input only props
    } = props;


    const [showErrorPopover, setShowErrorPopover] = React.useState(false);
    const [showErrorState, setShowErrorState] = React.useState(forceShowErrorState || false);
    const [hasTouched, setHasTouched] = React.useState(false);
    const { watch, register, formState } = useFormContext() || {};

    const error = withForm ? (get(formState.errors, name) ?? propsError) : propsError;

    let errorMessage = error?.message || '';
    if (Array.isArray(error)) {
      errorMessage = error.find((err) => err?.message).message || '';
    }

    let extraProps = {};
    let reactHookFormRefHandler;
    let currentInputValue = value;
    let reactHookFormProps: Partial<UseFormRegisterReturn> = {};

    // Using hook conditionally because it's safe to do that, currently these
    // props shouldn't change in a form or any implementation so this hook is
    // expected to run consistently.
    if (withLabel && withForm) {
      currentInputValue = watch(name);
    }

    if (withForm) {
      const { ref: registerRef, ...restRegisterProps } = register(
        name,
        {
          valueAsNumber: type === 'number',
        },
      );

      reactHookFormRefHandler = registerRef;
      reactHookFormProps = restRegisterProps;
    } else {
      extraProps = {
        value,
      };
    }

    const computedInputContainerStyles = css`
      ${inputContainerStyles};
      padding-top: ${withLabel ? LABEL_SIZE : 0}px;
    `;

    const handleBlur = e => {
      setShowErrorPopover(false);
      setShowErrorState(true);
      onBlur?.(e);
      (reactHookFormProps as UseFormRegisterReturn).onBlur?.(e);
    };

    const handleFocus = e => {
      setShowErrorPopover(true);
      onFocus?.(e);
    };

    const handleChange = (e) => {
      setHasTouched(true);
      setShowErrorPopover(false);
      setShowErrorState(false);

      onChange?.(e);
      (reactHookFormProps as UseFormRegisterReturn).onChange?.(e);
    };

    const handleKeyDown = (e) => {
      if (onKeyDown) {
        onKeyDown(e);
      }
      if (showErrorOnEnter && e.key === 'Enter' && currentInputValue) {
        setShowErrorState(true);
        setShowErrorPopover(true);
      }
    };

    const appendClassName = [
      'bs4-input-group-append',
      !!errorMessage && 'ml-0',
      required && 'input-required',
    ].filter(Boolean).join(' ');

    const alertIconClassName = [
      'icon icon-xss-smallest icon-warning',
      errorMessage && 'text-danger',
      required && 'mr-1',
    ].filter(Boolean).join(' ');

    return (
      <div
        className={className}
        css={computedInputContainerStyles}
      >
        {withLabel && (!!currentInputValue || labelAlwaysShown) && <div className='label'>{label || placeholder}</div>}
        <NvPopover
          className='nv-text-input'
          placement={popoverPlacement}
          overlayStyles={overlayStyles}
          preventOverflow={preventPopoverOverflow}
          content={<ValidationErrorMessage text={errorMessage} />}
          show={(errorOnTouching ? hasTouched : true) && (showErrorPopover || showErrorWithoutFocus) && !!errorMessage}
        >
          <div
            className={
              `bs4-input-group${(errorOnTouching ? hasTouched : true) && !!error && showErrorState ? ' bs4-invalid' : ''}${readOnly ? ' readonly' : ''}`
            }
          >
            <input
              {...restProps}
              {...extraProps}
              type={type}
              name={name}
              readOnly={readOnly}
              onBlur={handleBlur}
              onFocus={handleFocus}
              aria-label={ariaLabel}
              onChange={handleChange}
              onKeyDown={(e) => handleKeyDown(e)}
              placeholder={placeholder}
              ref={withForm ? mergeRefs(ref, reactHookFormRefHandler) : ref}
              className={`bs4-form-control${inputClassName ? ` ${inputClassName}` : ''}`}
            />
            {(required || !!errorMessage) && (
              <div className={appendClassName}>
                <div className='bs4-input-group-text'>
                  {!!errorMessage && showErrorState && (
                    <div className={alertIconClassName} />
                  )}
                  {required && '*'}
                </div>
              </div>
            )}
          </div>
        </NvPopover>
      </div>
    );
  },
);

export default NvTextInput;
