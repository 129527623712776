import t from 'react-translate';
import { css } from '@emotion/react';
import { almostBlack } from 'athena/styles/colors';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import NvIcon from 'shared/components/nv-icon';
import { config } from '@config/pendo.config.json';
import MentoringProgramContext from '../../context';
import MatchingRulesFlyoutModal from '../../modals/matching-rules-flyout-modal';
import MatchingRulesList from './matching-rules-list';

const styles = css`
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;

  .description {
    color: ${almostBlack};
  }
`;

const MatchingRules = () => {
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const [showFlyoutModal, setShowFlyoutModal] = useState<boolean>(false);

  return (
    <div css={styles}>
      {mentorshipProgramId && (
        <>
          <div className='mb-4'>
            <div className='title heading-3 mb-2'>
              {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.TITLE()}
            </div>
            <div className='description text-regular mb-2'>
              {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.DESCRIPTION()}
            </div>
            <Button
              onClick={() => setShowFlyoutModal(true)}
              variant='outline-primary'
              className='d-flex flex-row align-items-center'
              data-qa={config.pendo.athena.mentorshipProgram.settings.matchingRules.selectMatchingRules}
            >
              <NvIcon icon='create-new-post' size='smallest' />
              <span className='ml-2'>
                {t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.SELECT_MATCHING_RULES()}
              </span>
            </Button>
          </div>
          <MatchingRulesList />
        </>
      )}
      {showFlyoutModal && (
        <MatchingRulesFlyoutModal onClose={() => setShowFlyoutModal(false)} />
      )}
    </div>
  );
};

export default MatchingRules;
