import { css } from '@emotion/react';
import NvSwitch from 'shared/components/inputs/nv-switch';
import NvIcon from 'shared/components/nv-icon';
import { gray6 } from 'styles/global_defaults/colors';
import {
  doubleSpacing,
  extraLargeSpacing,
  standardSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';
import { NvTooltip } from 'shared/components/nv-tooltip';
import t from 'react-translate';

const styles = css`
  display: grid;
  align-items: center;
  column-gap: ${standardSpacing}px;
  grid-auto-rows: ${extraLargeSpacing}px;
  grid-template-columns: ${standardSpacing}px auto ${tripleSpacing}px;
  border-bottom: 1px solid ${gray6};
  .switch {
    width: ${doubleSpacing}px;
    height: ${standardSpacing}px;
    transform: scale(1.5);
    justify-self: center;
  }
  .attached-to-rule {
    opacity: 0.5;
  }
`;

type SelectableQuestionRowProps = {
  isHidden?: boolean;
  isSelected: boolean;
  questionId: number;
  questionText: string;
  onSelect: (questionId: number, isSelected: boolean) => void;
  isAttachedToRule: boolean;
};

const SelectableQuestionRow = ({
  isHidden = false,
  isSelected,
  questionId,
  questionText,
  onSelect,
  isAttachedToRule,
}: SelectableQuestionRowProps) => {
  const tooltip = isHidden ? t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.TOOLTIP.HIDDEN() : t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_RULES.FLYOUT_MODAL.TOOLTIP.SHOWN();

  return (
    <div css={styles}>
      <NvTooltip
        placement='top'
        preventOverflow={false}
        text={tooltip}
      >
        <NvIcon size='small' icon={isHidden ? 'hide' : 'view'} />
      </NvTooltip>
      <span className='text-large-body semi-bold'>{questionText}</span>
      <NvSwitch
        checked={isAttachedToRule || isSelected}
        disabled={isAttachedToRule}
        className={`switch align-self-center ${isAttachedToRule ? 'attached-to-rule' : ''}`}
        onChange={newState => onSelect(questionId, newState)}
      />
    </div>
  );
};

export default SelectableQuestionRow;
