import { ProgramLevelQuestionType } from 'redux/schemas/models/mentoring-program-profile';

export const DRAFT_PREFIX = 'draft';
export const NAME_MAX_LENGTH = 190;
export const DESCRIPTION_MAX_LENGTH = 250;

export const QUESTION_MAX_LENGTH = 255;

export const PAGE_SIZE = 20;
export type PageSizeParams = {
  pageSize: number;
};

export enum InputType {
  DROPDOWN = 'dropdown',
  LOCATION = 'location',
  LONG_TEXT = 'long_text',
  SHORT_TEXT = 'short_text',
  IMAGE = 'image',
}

export const getName = (fieldIdentifier: string, t) => {
  const namesMap = {
    picture_file_name: t.PROFILE.PROFILE_IMAGE(),
    headline: t.PROFILE.HEADLINE(),
    bio: t.PROFILE.BIO(),
    location_id: t.PROFILE.BIO_QUESTIONS.LOCATION(),
  };

  return namesMap[fieldIdentifier];
};

export const getInputType = (fieldIdentifier: string) => {
  const typesMap = {
    picture_file_name: InputType.IMAGE,
    headline: InputType.SHORT_TEXT,
    bio: InputType.LONG_TEXT,
    location_id: InputType.LOCATION,
  };

  return typesMap[fieldIdentifier];
};

export const getInputTypeLabel = (t, inputType: InputType) => {
  const DROPDOWN_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.DROPDOWN();
  const SHORT_TEXT_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.SHORT_TEXT();
  const LONG_TEXT_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.LONG_TEXT();
  const LOCATION_TEXT_VALUE = t.ORG_LEVEL_PROFILE.FIELD_MODIFIER.LOCATION();
  const IMAGE_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.ORG_LEVEL_FIELDS.ACCOUNT_LEVEL_FIELDS.INPUT_TYPE_LABEL.IMAGE();

  const inputTypeLabelsMap = {
    [InputType.DROPDOWN]: DROPDOWN_VALUE,
    [InputType.LONG_TEXT]: LONG_TEXT_VALUE,
    [InputType.SHORT_TEXT]: SHORT_TEXT_VALUE,
    [InputType.LOCATION]: LOCATION_TEXT_VALUE,
    [InputType.IMAGE]: IMAGE_VALUE,
  };

  return inputTypeLabelsMap[inputType];
};

export const getProgramLevelInputTypeLabelsMap = (t) => {
  const DROPDOWN_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INPUT_TYPES.DROPDOWN_VALUE();
  const CHECKLIST_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INPUT_TYPES.CHECKLIST_VALUE();
  const SHORT_TEXT_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INPUT_TYPES.SHORT_TEXT_VALUE();
  const LONG_TEXT_VALUE = t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.INPUT_TYPES.LONG_TEXT_VALUE();

  const inputTypeLabelsMap = {
    [ProgramLevelQuestionType.DROPDOWN]: DROPDOWN_VALUE,
    [ProgramLevelQuestionType.CHECKLIST]: CHECKLIST_VALUE,
    [ProgramLevelQuestionType.SHORT_TEXT]: SHORT_TEXT_VALUE,
    [ProgramLevelQuestionType.LONG_TEXT]: LONG_TEXT_VALUE,
  };

  return inputTypeLabelsMap;
};
