import React from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import AthenaTextarea from 'athena/components/textarea';
import AthenaTextInput from 'athena/components/text-input';
import {
  setSaveStatus,
  updateMentoringProgram,
} from 'redux/actions/mentoring-programs';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import {
  DESCRIPTION_MAX_LENGTH,
  NAME_MAX_LENGTH,
} from 'athena/components/mentoring-program/constants';
import { SaveStatus } from 'redux/schemas/app/mentoring-programs';
import { css } from '@emotion/react';
import AliasSetting, { aliasValidationSchema } from './alias-setting';
import MaxMenteesSetting from './max-mentees-setting';

type FormValue = {
  name: string;
  maxMentees: number;
  mentorAlias: string;
  menteeAlias: string;
  description: string;
};

const styles = css`
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
`;

const ProgramSetup = () => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = React.useContext(MentoringProgramContext);
  const mentoringProgramAliases = useSelector(state => getMentoringProgramAliases(state, mentoringProgram?.id));

  const aliases = [
    {
      name: 'mentorAlias',
      role: MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR,
    },
    {
      name: 'menteeAlias',
      role: MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE,
    },
  ];

  const validationSchema = Yup.object().shape({
    mentorAlias: aliasValidationSchema,
    menteeAlias: aliasValidationSchema,
    maxMentees: Yup.number()
      .min(1)
      .max(Infinity),
    name: Yup.string()
      .required(t.VALIDATION.REQUIRED())
      .max(NAME_MAX_LENGTH, t.VALIDATION.MAX_LENGTH(`${NAME_MAX_LENGTH}`)),
    description: Yup.string().max(
      DESCRIPTION_MAX_LENGTH,
      t.VALIDATION.MAX_LENGTH(`${DESCRIPTION_MAX_LENGTH}`),
    ),
  });

  const getDefaultValues = React.useCallback(
    () => ({
      name: mentoringProgram?.name ?? '',
      description: mentoringProgram?.description ?? '',
      maxMentees: mentoringProgram?.concurrentMentorConnection ?? 1,
      mentorAlias: mentoringProgramAliases.MentorAlias,
      menteeAlias: mentoringProgramAliases.MenteeAlias,
    }),
    [mentoringProgram, mentoringProgramAliases],
  );

  const rhfMethods = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: getDefaultValues(),
    resolver: yupResolver(validationSchema),
  });

  const { reset, handleSubmit } = rhfMethods;

  const { isValid, isDirty } = rhfMethods.formState;

  React.useEffect(() => {
    reset(getDefaultValues());
  }, [reset, getDefaultValues]);

  const onSubmit = (form: FormValue) => {
    if (isValid && isDirty) {
      dispatch(setSaveStatus({ newStatus: SaveStatus.IN_PROGRESS }));
      wrapThunkAction(
        dispatch(
          updateMentoringProgram({
            id: mentoringProgram.id,
            autoMatchingEnabled: mentoringProgram.autoMatchingEnabled || false,
            ...form,
          }),
        ),
      )
        .then(() => {
          dispatch(setSaveStatus({ newStatus: SaveStatus.COMPLETED }));
        })
        .finally(() => {
          setTimeout(() => {
            dispatch(setSaveStatus({ newStatus: null }));
          }, 3000);
        });
    }
  };

  return (
    <FormProvider {...rhfMethods}>
      <form onBlur={handleSubmit(onSubmit)}>
        {mentoringProgram && (
          <div css={styles}>
            <div className='mb-4'>
              <div className='title heading-3 mb-2'>
                {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.TITLE()}
              </div>
              <div className='heading-5 pt-4 mb-2'>
                {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.NAME_DESCRIPTION()}
              </div>
              <AthenaTextInput
                withForm
                required
                name='name'
                className='mb-2'
                placeholder={t.MENTORING_PROGRAMS.PROGRAM_NAME_LABEL()}
              />
              <AthenaTextarea
                withForm
                name='description'
                placeholder={t.MENTORING_PROGRAMS.DESCRIPTION_LABEL()}
              />
            </div>
            <div className='mb-4'>
              <div className='heading-5 mb-2'>
                {t.COURSE_ADMIN_MEGA_MENU.ALIASES.LABEL()}
              </div>
              <div className='gray-1 text-medium mb-2'>
                {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.ALIASES.DESCRIPTION()}
              </div>
              {aliases.map(({ name, role }) => (
                <AliasSetting key={name} name={name} role={role} />
              ))}
            </div>
            <div className='mb-5'>
              <div className='heading-5 mb-2'>
                {t.MENTORING_PROGRAMS.CONNECTIONS.TITLE()}
              </div>
              <MaxMenteesSetting name='maxMentees' />
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default ProgramSetup;
