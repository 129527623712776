import { BaseUser, User } from 'redux/schemas/models/my-account';

// TODO: Update enum values when backend corrects the value
export enum MentorshipStatus {
  MATCHED = 'matched',
  UNMATCHED = 'unmatched',
  MATCHED_WITH_AVAILABILITY = 'matched with availability',
}

export enum MentoringProgramRole {
  MENTORSHIP_PROGRAM_MENTOR = 'MENTORSHIP_PROGRAM_MENTOR',
  MENTORSHIP_PROGRAM_ADMIN = 'MENTORSHIP_PROGRAM_ADMIN',
  MENTORSHIP_PROGRAM_MENTEE = 'MENTORSHIP_PROGRAM_MENTEE',
}

export const AdminBitValue = 512;
export const MentorBitValue = 256;
export const MenteeBitValue = 1024;

type MentoringProgramPermissionBitValue = typeof AdminBitValue | typeof MentorBitValue | typeof MenteeBitValue;

export type MentoringProgramEnrollmentRole = {
  name: MentoringProgramRole;
  permission: MentoringProgramPermissionBitValue;
};

export type MentoringProgramEnrollment = {
  id: number
  user: User;
  userId: number;
  mentorshipProgramId: number;
  mentorshipStatus: MentorshipStatus;
  role: MentoringProgramEnrollmentRole;
  assignedConnection: { id: number; user: User; }[];
};

export type MentoringProgramEnrollmentResult = {
  newlyEnrolled: BaseUser[];
  existing: BaseUser[];
};
