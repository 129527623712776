import { mergeWith } from 'lodash';
import { normalize } from 'normalizr';
import { createReducer } from '@reduxjs/toolkit';

import { replaceArrays } from 'shared/lodash-utils';
import {
  MentoringProgramSchema,
  MentoringProgramsSchema,
} from 'redux/schemas/api/mentoring-programs';
import {
  createMentoringProgram,
  fetchProgramCounts,
  getMentoringProgram,
  getMentoringPrograms,
  setAutoMatchingLastUpdated,
  setAutoMatchingStatus,
  setConnectionsCount,
  setSaveStatus,
  updateMentoringProgram,
} from 'redux/actions/mentoring-programs';
import { initialRootState } from '.';

const setProgramActionHandler = (state, action) => {
  const normalized = normalize(action.payload, MentoringProgramSchema);

  mergeWith(state.models, normalized.entities, replaceArrays);
};

export default createReducer(initialRootState, builder => {
  builder
    .addCase(createMentoringProgram.fulfilled, setProgramActionHandler)
    .addCase(updateMentoringProgram.fulfilled, setProgramActionHandler)
    .addCase(getMentoringProgram.fulfilled, setProgramActionHandler)
    .addCase(getMentoringPrograms.fulfilled, (state, action) => {
      const normalized = normalize(
        action.payload.response,
        MentoringProgramsSchema,
      );

      mergeWith(state.models, normalized.entities, replaceArrays);
    })
    .addCase(fetchProgramCounts.pending, (state, action) => {
      if (action.meta.arg.setLoading ?? true) {
        state.app.mentoringProgram.programCounts.isLoading = true;
      }
    })
    .addCase(fetchProgramCounts.fulfilled, (state, action) => {
      state.app.mentoringProgram.programCounts.result = action.payload;
      state.app.mentoringProgram.programCounts.isLoading = false;
    })
    .addCase(fetchProgramCounts.rejected, state => {
      state.app.mentoringProgram.programCounts.isLoading = false;
    })
    .addCase(setAutoMatchingStatus, (state, action) => {
      const { autoMatchingStatus } = action.payload;
      state.app.mentoringProgram.autoMatching = {
        status: autoMatchingStatus,
      };
    })
    .addCase(setAutoMatchingLastUpdated, (state, action) => {
      const { mentorshipProgramId, autoMatchingLastUpdated } = action.payload;
      state.models.mentoringPrograms[
        mentorshipProgramId
      ].autoMatchingLastUpdated = autoMatchingLastUpdated;
    })
    .addCase(setConnectionsCount, (state, action) => {
      const { totalConnectionsCount } = action.payload;
      state.app.mentoringProgram.programCounts.result = {
        ...state.app.mentoringProgram.programCounts.result,
        connectionsCount: totalConnectionsCount,
      };
    })
    .addCase(setSaveStatus, (state, action) => {
      const { newStatus } = action.payload;
      state.app.mentoringProgram.settings.saveStatus = newStatus;
    });
});
