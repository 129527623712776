import t from 'react-translate';
import { useContext, useEffect, useMemo } from 'react';
import {
  MentorshipProgramProfileQuestion,
  OrgLevelQuestion,
} from 'redux/schemas/models/mentoring-program-profile';
import { getMentorshipProgramProfileQuestionsList } from 'redux/selectors/mentorship-program-profile';
import { useInView } from 'react-intersection-observer';
import { getMentorshipProgramProfileQuestions, ProgramProfileQuestionType } from 'redux/actions/mentoring-program-profile';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { useAppDispatch } from 'redux/store';
import { getName, PAGE_SIZE } from '../../constants';
import { GetProgramProfileQuestionParams } from '../../program-configuration/program-profile-setup/types';
import SelectableQuestionRow from './selectable-question-row';
import MentoringProgramContext from '../../context';

const filterSelectableQuestions = (
  orgLevelQuestions: MentorshipProgramProfileQuestion[],
) => {
  const selectableQuestions = [];
  orgLevelQuestions?.forEach(question => {
    if (question.isNovoedQuestion) {
      const { id: questionId, novoedQuestionName, isRequired, isAttachedToRule } = question;
      if (isRequired) {
        const newSelectableQuestion = {
          questionId,
          questionText: getName(novoedQuestionName, t),
          isHidden: false,
          isAttachedToRule,
        };
        selectableQuestions.push(newSelectableQuestion);
      }
    } else {
      const { id: questionId, profileQuestion, isRequired, isAttachedToRule } = question;
      if ((profileQuestion as OrgLevelQuestion)?.profileSetting) {
        const { profileSetting } = profileQuestion as OrgLevelQuestion;
        const { name: questionText, isHidden, isCsvManaged, isIntegrated } = profileSetting || {};
        if (isRequired || isCsvManaged || isIntegrated) {
          const newSelectableQuestion = {
            questionId,
            questionText,
            isHidden,
            isAttachedToRule,
          };
          selectableQuestions.push(newSelectableQuestion);
        }
      }
    }
  });
  return selectableQuestions;
};

type SelectableQuestion = {
  questionId: number;
  questionText: string;
  isHidden: boolean;
  isAttachedToRule: boolean;
};

type SelectableOrgLevelQuestionsProps = {
  selected: number[]
  onSelect: (questionId: number, isSelected: boolean) => void;
};

const SelectableOrgLevelQuestions = ({
  selected,
  onSelect,
}: SelectableOrgLevelQuestionsProps) => {
  const dispatch = useAppDispatch();
  const { mentoringProgram } = useContext(MentoringProgramContext);
  const { id: mentorshipProgramId } = mentoringProgram || {};
  const { ref: endRef, inView } = useInView();

  const params = useMemo(
    () => ({
      type: ProgramProfileQuestionType.ORG_LEVEL,
      pageSize: PAGE_SIZE,
    }),
    [],
  );

  const { result: orgLevelQuestions, loadMore } = usePaginatedApi<
  MentorshipProgramProfileQuestion,
  GetProgramProfileQuestionParams
  >(
    p => dispatch(
      getMentorshipProgramProfileQuestions({ mentorshipProgramId, ...p }),
    ).then(action => action.payload),
    params,
    getMentorshipProgramProfileQuestionsList,
  );

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [loadMore, inView]);

  const selectableQuestions: SelectableQuestion[] = useMemo(
    () => filterSelectableQuestions(orgLevelQuestions),
    [orgLevelQuestions],
  );

  return (
    <>
      {selectableQuestions.map(({ questionId, questionText, isHidden, isAttachedToRule }) => (
        <SelectableQuestionRow
          key={questionId}
          isHidden={isHidden}
          isSelected={selected.includes(questionId)}
          questionId={questionId}
          questionText={questionText}
          onSelect={onSelect}
          isAttachedToRule={isAttachedToRule}
        />
      ))}
      <div ref={endRef} />
    </>
  );
};

export default SelectableOrgLevelQuestions;
